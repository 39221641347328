import React, { useState } from "react";
import { FormControl, Select, MenuItem } from "@mui/material";
import { country_codes } from "../../utils/countryCodes";

export const CountryCodeSelect = ({ codeChange }) => {
  const [value, setValue] = useState();
  const onChange = (e) => {
    setValue(e.target.value);
    codeChange(e.target.value.split(" ")[0]);
  };
  return (
    <FormControl width="165px" required>
      <Select
        labelId="country-code-select-label"
        id="country-code-select"
        name="Country code"
        value={value}
        label="Age"
        onChange={onChange}
      >
        {country_codes.map((country) => (
          <MenuItem key={country.dial_code} value={country.dial_code}>
            {country.dial_code} {country.code}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
