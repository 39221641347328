import React, { useState } from "react";
import {
  Box,
  Card,
  Button,
  Typography,
  Stack,
  OutlinedInput,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { FormPassword } from "../FormPassword";
import { CountryCodeSelect } from "../CountryCodeSelect";
import { PageLayout } from "../PageLayout";
import "./SignIn.scss";

export const SignIn = ({
  username,
  password,
  errorMessage,
  signinErrorMessage,
  isSigninError,
  showCountryCode,
  isError,
  showPassword,
  onUserNameChange,
  onPasswordChange,
  toggleShowPassword,
  signIn,
  codeChange,
}) => {
  const [userPassword, setUserPassword] = useState(password);
  const [isRoleSelected, setIsRoleSelected] = useState(false);
  const [role, setRole] = useState("");

  const onSelectRole = (role) => {
    setIsRoleSelected(true);
    setRole(role);
  };

  return (
    <PageLayout isShow={false}>
      {isRoleSelected ? (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Card
            sx={{
              width: "100%",
              maxWidth: 500,
              boxSizing: "border-box",
              margin: "0 auto",
              padding: "30px",
            }}
          >
            <Stack>
              <Stack align={"center"}>
                <Typography fontSize="2rem" fontWeight="bold">
                  Sign in to your account
                </Typography>
              </Stack>
              <Box margin="24px 0 0 !important">
                <form onSubmit={signIn}>
                  <Stack>
                    {isSigninError && (
                      <Typography
                        paragraph={true}
                        color="red"
                        textAlign="center"
                        padding="0 0 10px"
                      >
                        {signinErrorMessage}
                      </Typography>
                    )}
                    <Box sx={{ display: "flex", padding: "0 0 30px" }}>
                      {showCountryCode && (
                        <CountryCodeSelect codeChange={codeChange} />
                      )}
                      <FormControl
                        required
                        error={isError}
                        variant="outlined"
                        sx={{ width: "100%" }}
                      >
                        <InputLabel htmlFor="email-phone">
                          Email or Phone number or Username
                        </InputLabel>
                        <OutlinedInput
                          id="email-phone"
                          type="text"
                          name="username"
                          placeholder={"Email or Phone number or Username"}
                          value={username}
                          autoFocus={true}
                          onChange={onUserNameChange}
                          label={"Email or Phone number or Username"}
                        />
                        <FormHelperText>{errorMessage}</FormHelperText>
                      </FormControl>
                    </Box>
                    <FormPassword
                      label="Password"
                      name="password"
                      showPassword={showPassword}
                      toggleShowPassword={toggleShowPassword}
                      placeholder="password"
                      inputOnChange={(e) => {
                        onPasswordChange(e);
                        setUserPassword(e.target.value);
                      }}
                      value={userPassword}
                      styles={{
                        padding: "0 0 30px",
                      }}
                    />
                    <Stack margin="0">
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{ fontSize: "1rem", fontWeight: "bold" }}
                      >
                        Sign in
                      </Button>
                      <Stack
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          margin: "20px 0 0",
                        }}
                      >
                        <NavLink to={`/forgetpassword`}>
                          <Typography paragraph={true} color="brand.600">
                            Forgot password?
                          </Typography>
                        </NavLink>
                      </Stack>
                      <Button
                        variant="text"
                        onClick={() => setIsRoleSelected(false)}
                        sx={{ margin: "10px 0 0" }}
                      >
                        <Typography
                          color="brand.600"
                          sx={{
                            textTransform: "uppercase",
                            textDecoration: "underline",
                          }}
                        >
                          back
                        </Typography>
                      </Button>
                    </Stack>
                  </Stack>
                </form>
              </Box>
            </Stack>
          </Card>
        </Box>
      ) : (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: { xs: "25px 0", md: "0" },
          }}
          className="signin-wrap"
        >
          <Box className="signin-box" onClick={() => onSelectRole("parent")}>
            <div>
              <img
                src="/img/parent.svg"
                alt="parent"
                style={{ width: "100%", height: "285px" }}
              />
            </div>
            i'm a Parent
          </Box>
          <Box className="signin-box" onClick={() => onSelectRole("student")}>
            <div>
              <img
                src="/img/student.svg"
                alt="student"
                style={{ width: "100%", height: "285px" }}
              />
            </div>
            i'm a Student
          </Box>
        </Box>
      )}
    </PageLayout>
  );
};
