import React, { useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { FormInput } from "../FormInput";
import { FormPassword } from "../FormPassword";

export const ChangePassword = ({
  code,
  newPassword,
  newPasswordAgain,
  changePasswordErrorMessage,
  isCodeError,
  isPasswordError,
  isRepeatPasswordError,
  onPasswordChange,
  onCodeChange,
  onResetPasswordSubmit,
  showPassword,
  toggleShowPassword,
}) => {
  const [userNewPassword, setUserNewPassword] = useState(newPassword);
  const [userRepeatPassword, setUserRepeatPassword] =
    useState(newPasswordAgain);
  return (
    <>
      <Typography
        component="h1"
        variant="h5"
        sx={{
          lineHeight: "1.1",
          margin: "0 0 20px",
        }}
        fontSize="2rem"
        fontWeight="bold"
      >
        Enter new password
      </Typography>
      <form onSubmit={onResetPasswordSubmit}>
        {isCodeError && (
          <Typography color="red" textAlign="center" padding="0 0 10px">
            {changePasswordErrorMessage}
          </Typography>
        )}
        <FormInput
          label="Code"
          name="code"
          type="text"
          placeholder=""
          inputOnChange={onCodeChange}
          value={code}
          styles={{
            margin: "0 0 20px",
            width: "100%",
          }}
          isRequired={true}
          autoFocus={true}
        />
        <FormPassword
          label="New Password"
          name="new_password"
          showPassword={showPassword}
          toggleShowPassword={toggleShowPassword}
          placeholder=""
          inputOnChange={(e) => {
            onPasswordChange(e);
            setUserNewPassword(e.target.value);
          }}
          value={userNewPassword}
          formProps={{ error: isPasswordError }}
          styles={{
            padding: "0 0 20px",
            width: "100%",
          }}
          errorMessage={
            <>
              <Typography
                paragraph={true}
                sx={{
                  margin: "0",
                  color: newPassword.length >= 8 ? "green" : "red",
                }}
              >
                Password must contain at least 8 characters.
              </Typography>
            </>
          }
        />
        <FormPassword
          label="Enter New Password Again"
          name="new_password_again"
          showPassword={showPassword}
          toggleShowPassword={toggleShowPassword}
          placeholder=""
          inputOnChange={(e) => {
            onPasswordChange(e);
            setUserRepeatPassword(e.target.value);
          }}
          value={userRepeatPassword}
          formProps={{ error: isRepeatPasswordError }}
          styles={{
            padding: "0 0 20px",
            width: "100%",
          }}
          errorMessage={
            <Typography
              paragraph={true}
              sx={{
                margin: "0",
                color: "red",
              }}
            >
              Your password should match with the above one.
            </Typography>
          }
        />
        <Stack spacing={6}>
          <Button
            type="submit"
            variant="contained"
            sx={{
              margin: "0 0 20px",
              fontSize: "1rem",
              fontWeight: "bold",
            }}
          >
            Change Password
          </Button>
        </Stack>
      </form>
    </>
  );
};
