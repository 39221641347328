import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { AppDrawer } from "../AppDrawer";
import { Header } from "../Header";
import { useStore } from "../../Store";
import "./PageLayout.scss";

export const PageLayout = ({
  children,
  open,
  login,
  toggleDrawer,
  user,
  isAuthenticated,
  Auth,
  userRole,
  isShow,
  isVerificationPage = false
}) => {
  const isChildHasNoParent = useStore((state) => state.isChildHasNoParent);
  return (
    <Box className="design2">
      <Box sx={{ display: "flex" }} className="design">
        <Header
          toggleDrawer={toggleDrawer}
          open={open}
          isAuthenticated={isAuthenticated}
          user={user}
          Auth={Auth}
          isShow={isShow}
        />
        <AppDrawer
          open={open}
          toggleDrawer={toggleDrawer}
          listItems={
            userRole === "Parent"
              ? [
                  { id: 1, name: "Home", link: "/", icon: "home" },
                  {
                    id: 2,
                    name: "Billing",
                    link: "/billing",
                    icon: "billing",
                  },
                  { id: 3, name: "Profile", link: "/profile", icon: "profile" },
                ]
              : isChildHasNoParent
              ? [
                  { id: 1, name: "Home", link: "/", icon: "home" },
                  {
                    id: 2,
                    name: "Billing",
                    link: "/billing",
                    icon: "billing",
                  },
                  { id: 4, name: "Profile", link: "/profile", icon: "profile" },
                ]
              : [
                  { id: 1, name: "Home", link: "/", icon: "home" },
                  { id: 4, name: "Profile", link: "/profile", icon: "profile" },
                ]
          }
        />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            ...(!isVerificationPage ? {height: "100vh"} : {}),
            ...(!isVerificationPage ? {overflow: "auto"} : {}),
            ...(!isVerificationPage ? {margin: "65px 0 0"} : {}),
            zIndex: 1,
            ...(window.location.href.includes("signup")
              ? {
                  backgroundImage:
                    "linear-gradient(0deg,hsla(0,0%,100%,.13),rgba(0,0,0,.16)),linear-gradient(180deg,#0a5eff,#0a5eff)",
                }
              : {}),
          }}
        >
          {/* <Box className="background-wrapper"></Box> */}
          <Container maxWidth="lg" sx={{ mt: 8, mb: 15 }}>
            {children}
          </Container>
        </Box>
      </Box>
    </Box>
  );
};
