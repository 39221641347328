import { gql } from "@apollo/client";

export const PRODUCTS = gql`
  query Products($where: ProductWhereInput, $first: Int) {
    products(where: $where, first: $first) {
      id
      productName
      productType
      productCoverPath
    }
  }
`;
export const PRODUCTDESCRIPTION = gql`
  query Product($where: ProductWhereUniqueInput!) {
    product(where: $where) {
      productDescription
      productHeading
      productName
      productType
    }
  }
`;
export const BUNDLEDESCRIPTION = gql`
  query Bundle($where: BundleWhereInput) {
    bundle(where: $where) {
      bundleDescription
      bundleHeading
      bundleName
      bundleType
    }
  }
`;

export const PRODUCT = gql`
  query Product($where: ProductWhereUniqueInput!) {
    product(where: $where) {
      id
      productDescription
      productHeading
      productName
      productType
      productCategory {
        id
        name
        parentCategory {
          id
          name
        }
      }
      productSubscriptionPlan {
        price
        id
        currency
      }
      productContent {
        dynoList(orderBy: listIndex_ASC) {
          id
          listTitle
          listName
          dynosUnderList {
            id
            dyno {
              id
              dynoTitle
              dynoType {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const PRODUCTS_CONNECTION = gql`
  query ProductsConnection(
    $where: ProductWhereInput
    $orderBy: ProductOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    productsConnection(
      where: $where
      first: $first
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      last: $last
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          productName
          productType
          productCoverPath
          productCategory {
            id
            name
          }
          productSubscriptionPlan {
            id
            price
            currency
          }
        }
      }
    }
  }
`;

export const PRODUCTS_COUNT = gql`
  query ProductsCount($where: ProductWhereInput) {
    productsConnection(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const PRODUCT_FILTERS = gql`
  query ProductFilters {
    dynoCategories {
      id
      name
    }
    instructors {
      id
      InstructorName
    }
    dynoGrades {
      id
      name
    }
  }
`;

export const USER = gql`
  query Users($where: IndParentWhereInput) {
    indParents(where: $where) {
      id
      pupil {
        id
        firstName
        lastName
        username
        email
        phone
        otherInfo
      }
      parent {
        id
        firstName
        lastName
        username
        email
        phone
      }
    }
  }
`;

export const PURCHASED_BOOKS_APPS = gql`
  query UserPurchasedBooksAndApps(
    $where: UserPurchasedBooksAndAppsWhereInput!
  ) {
    userPurchasedBooksAndApps(where: $where) {
      books {
        id
        productName
        productHeading
        productCoverPath
        status
      }
      apps {
        id
        name
        heading
        coverPath
        status
      }
    }
  }
`;

export const SUBSCRIPTIONS = gql`
  query Subscriptions(
    $productWhere: IndPurchasedProductWhereInput
    $bundleWhere: IndPurchasedBundleWhereInput
    $productOrderBy: [IndPurchasedProductOrderByWithRelationInput]
    $bundleOrderBy: [IndPurchasedBundleOrderByWithRelationInput]
  ) {
    indPurchasedProducts(where: $productWhere, orderBy: $productOrderBy) {
      id
      product {
        id
        productName
        productHeading
        productCoverPath
      }
      productSubscriptionPlan {
        id
        planName
        planShortDescription
        gatewayPlanId
        gatewayProvider
        frequency
      }
      student {
        id
        firstName
        lastName
      }
      status
      otherInfo
      accessUntil
      gatewaySubscriptionId
    }
    indPurchasedBundles(where: $bundleWhere, orderBy: $bundleOrderBy) {
      id
      bundle {
        id
        bundleName
        bundleHeading
        bundleCoverPath
      }
      bundleSubscriptionPlan {
        id
        planName
        gatewayPlanId
        gatewayProvider
        planShortDescription
        frequency
      }
      student {
        id
        firstName
        lastName
      }
      status
      otherInfo
      accessUntil
      gatewaySubscriptionId
    }
  }
`;

export const RAZORPAY_SUBSCRIPTION_INVOICES = gql`
  query SubscriptionInvoicesRazorpay(
    $where: SubscriptionInvoicesRazorpayInput!
  ) {
    subscriptionInvoicesRazorpay(where: $where) {
      id
      amount
      amount_paid
      amount_due
      paid_at
      status
      short_url
    }
  }
`;

export const STRIPE_SUBSCRIPTION_INVOICES = gql`
  query SubscriptionInvoicesStripe(
    $where: SubscriptionInvoicesStripeWhereInput!
  ) {
    subscriptionInvoicesStripe(where: $where) {
      id
      amount
      amount_paid
      amount_due
      paid_at
      status
      short_url
    }
  }
`;

export const CHILD_ENROLLED_COURSES = gql`
  query ChildEnrolledCourses(
    $bundle_where: IndPurchasedBundleWhereInput
    $product_where: IndPurchasedProductWhereInput
    $qapp_where: QAppWhereInput
  ) {
    indPurchasedBundles(where: $bundle_where) {
      bundle {
        id
        bundleName
        isBundleApp
        QApp(where: $qapp_where) {
          id
        }
      }
      accessUntil
      status
    }
    indPurchasedProducts(where: $product_where) {
      product {
        id
        productName
      }
      accessUntil
      status
    }
  }
`;
