import React from "react";
import {
  Box,
  Button,
  Stack,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";
import { CountryCodeSelect } from "../CountryCodeSelect";

export const ForgotPassword = ({
  username,
  errorMessage,
  showCountryCode,
  onUsernameChange,
  isError,
  onForgotPasswordSubmit,
  codeChange,
}) => {
  return (
    <>
      <Typography
        variant="h1"
        sx={{
          lineHeight: "1.1",
        }}
        fontSize="2rem"
        fontWeight="bold"
        margin="0 0 20px"
      >
        Forgot your password?
      </Typography>
      <Typography paragraph={true} fontSize={{ sm: "16px", md: "18px" }}>
        Enter your Email or Phone number or Username below and we will send a
        message to reset your password
      </Typography>
      <form onSubmit={onForgotPasswordSubmit}>
        <Box sx={{ display: "flex", padding: "0 0 30px" }}>
          {showCountryCode && <CountryCodeSelect codeChange={codeChange} />}
          <FormControl
            required
            error={isError}
            variant="outlined"
            sx={{ width: "100%" }}
          >
            <InputLabel htmlFor="username">
              Email or Phone number or Username
            </InputLabel>
            <OutlinedInput
              id="username"
              type="text"
              name="username"
              placeholder="Email or Phone number or Username"
              value={username}
              onChange={onUsernameChange}
              label="Email or Phone number or Username"
              autoFocus={true}
            />
            <FormHelperText>{errorMessage}</FormHelperText>
          </FormControl>
        </Box>
        <Stack>
          <Button
            type="submit"
            variant="contained"
            sx={{
              margin: "0 0 20px",
              fontSize: "1rem",
              fontWeight: "bold",
            }}
          >
            Reset my password
          </Button>
        </Stack>
      </form>
    </>
  );
};
