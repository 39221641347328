import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";

export const CheckoutError = ({ loadingCallback }) => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/billing");
    }, 1000);
  }, [navigate]);

  return (
    <Box
      sx={{
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          position: "relative",
          textAlign: "center",
        }}
      >
        <img
          src="/img/warning-sign.svg"
          alt="error"
          style={{
            margin: "0 0 20px",
            width: "25%",
          }}
        />
        <Typography
          variant="p"
          component="p"
          sx={{
            fontSize: "20px",
            fontWeight: "400",
            fontFamily: "Open Sans ,sans-serif, Arial",
            margin: "0 0 20px",
            color: "#e53e3e",
          }}
        >
          Error! Couldn't complete the payment.
        </Typography>
        <Typography
          variant="p"
          component="p"
          sx={{
            fontSize: "20px",
            fontFamily: "Open Sans ,sans-serif, Arial",
            margin: "0 0 20px",
          }}
        >
          Try again
        </Typography>
      </Box>
    </Box>
  );
};
