import { useState, useEffect, useMemo } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  InputBase,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import { visuallyHidden } from "@mui/utils";
import { SelectDropDown } from "../SelectDropDown";
import "./Datatable.scss";

const SearchBox = styled("div")(({ theme }) => ({
  position: "relative",
  border: "1px solid rgba(0, 0, 0, 0.23)",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 1),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.8),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  marginBottom: "20px",
  width: "233px",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: "15px 8px 15px 0",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
}));

export const DataTable = ({
  originalRows = [],
  columns,
  visibleFields = [],
  searchColumns = [],
  noDataMsg = "No data found.",
}) => {
  const [rows, setRows] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searched, setSearched] = useState("");
  const [column, setColumn] = useState("");

  useEffect(() => {
    setRows(originalRows);
  }, [originalRows]);

  useEffect(() => {
    if (searchColumns.length > 0) setColumn(searchColumns[0].value);
  }, [searchColumns]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const requestSearch = (searchedVal) => {
    const filteredRows = originalRows.filter((row) => {
      return row[column].toLowerCase().includes(searchedVal.toLowerCase());
    });
    setSearched(searchedVal);
    setRows(filteredRows);
  };

  const columnsData = useMemo(
    () => columns.filter((column) => visibleFields.includes(column.field)),
    [columns, visibleFields]
  );

  return (
    <Box
      sx={{
        width: "100%",
        border: "1px solid",
        borderColor: "rgba(0, 176, 255, 0.4)",
        color: "rgb(66, 71, 112)",
        fontFamily: "Open Sans,sans-serif",
        fontSize: "15px",
        letterSpacing: "normal",
        background: "rgb(255, 255, 255)",
        borderRadius: "20px",
        boxShadow: "rgb(0 176 255 / 20%) 4px 16px 24px 0px",
        padding: { xs: "20px", md: "20px 0" },
        boxSizing: "border-box",
      }}
      id="no-more-tables"
    >
      <Paper sx={{ width: "100%", mb: 2, boxShadow: "none" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: { xs: "center", md: "flex-end" },
          }}
        >
          <SelectDropDown
            styles={{
              width: { xs: "100%", md: "233px" },
              margin: { xs: "0 0 20px", md: "0 20px 0 0" },
            }}
            label="Select a column"
            value={column}
            name="column"
            options={searchColumns}
            handleChange={(e) => setColumn(e.target.value)}
          />
          <SearchBox>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="search"
              inputProps={{ "aria-label": "search" }}
              value={searched}
              onChange={(e) => requestSearch(e.target.value)}
            />
          </SearchBox>
        </Box>
        <TableContainer>
          <Table
            sx={{ minWidth: { xs: "100%", md: 750 } }}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow border="none">
                {columnsData.map((headCell, i) => (
                  <TableCell
                    key={headCell.field}
                    sx={{
                      color: "rgb(66, 71, 112)",
                      backgroundColor: "rgb(244, 246, 248)",
                      border: 0,
                      ...(i === 0
                        ? {
                            borderTopLeftRadius: "8px",
                            borderBottomLeftRadius: "8px",
                            boxShadow: "rgb(255 255 255) 8px 0px 0px inset",
                          }
                        : {}),
                      ...(columnsData.length - 1 === i
                        ? {
                            borderTopRightRadius: "8px",
                            borderBottomRightRadius: "8px",
                            boxShadow: "rgb(255 255 255) -8px 0px 0px inset",
                          }
                        : {}),
                    }}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.field}
                      direction={orderBy === headCell.field ? order : "asc"}
                      onClick={createSortHandler(headCell.field)}
                      sx={{
                        color: "rgb(66, 71, 112)",
                        fontSize: "15px",
                        ":hover": {
                          color: "rgb(66, 71, 112)",
                          background: "none",
                        },
                      }}
                    >
                      {headCell.headerName}
                      {orderBy === headCell.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={`${row.id}${index}`}
                      sx={{
                        ":hover": {
                          backgroundColor: "rgba(0, 176, 255, 0.16) !important",
                        },
                      }}
                    >
                      {columnsData.map((cd, i) => {
                        if (!!cd.renderCell)
                          return (
                            <TableCell
                              data-title={cd.headerName}
                              key={`${cd.field}${i}`}
                            >
                              {cd.renderCell(row)}
                            </TableCell>
                          );
                        return (
                          <TableCell
                            data-title={cd.headerName}
                            key={`${cd.field}${i}`}
                            sx={{ fontSize: "15px" }}
                          >
                            {row[cd.field]}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};
