import React, { useState, useEffect } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Box, Button, Chip, Typography, Link, IconButton } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import {
  SUBSCRIPTIONS,
  RAZORPAY_SUBSCRIPTION_INVOICES,
  STRIPE_SUBSCRIPTION_INVOICES,
} from "../../graphql/queries";
import {
  CANCELRAZORPAYSUBSCRIPTION,
  CREATESUBSCRIPTION,
  RAZORPAYCHECKOUTSUCCESS,
  UPDATEINDPURCHASEDPRODUCT,
  UPDATEINDPURCHASEDBUNDLE,
  CANCELSTRIPESUBSCRIPTION,
  STRIPE_CHECKOUT,
} from "../../graphql/mutations";
import { useStore } from "../../Store";
import { ModalBox } from "../../components/ModalBox";
import { DataTable } from "../../components/DataTable";
import { formatDate, formatInvoiceDate } from "../../utils/functions";
import { CheckoutFormWithoutChild } from "../../components/CheckoutFormWithoutChild";

export const SubscriptionsPage = ({
  loadingCallback,
  productType = "Book",
}) => {
  const user = useStore((state) => state.user);
  const children = useStore((state) => state.children);
  const isChildHasNoParent = useStore((state) => state.isChildHasNoParent);
  const indParentId = useStore((state) => state.indParentId);
  const [subscriptions, setSubscriptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [openChildModal, setOpenChildModal] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [subscription, setSubscription] = useState({});
  const [isRenew, setIsRenew] = useState(false);
  const [isNewChild, setIsNewChild] = useState(false);
  const [error, setError] = useState("");
  const [showInvoice, setShowInvoice] = useState(false);
  const [child, setChild] = useState({
    firstName: "",
    lastName: "",
    password: "",
  });
  const [isRazorpay, setIsRazorpay] = useState(true);
  const [modalText, setModalText] = useState({
    title: "Cancel subscription.",
    description: "Are you sure you want to cancel the subscription.",
  });

  const [getSubscriptions] = useLazyQuery(SUBSCRIPTIONS, {
    onCompleted: (data) => {
      loadingCallback(false, "");
      setSubscriptions([
        ...data.indPurchasedProducts?.map((purchasedProduct) => ({
          ...purchasedProduct?.productSubscriptionPlan,
          id: purchasedProduct?.id,
          type: "Course",
          productSubscriptionPlanId:
            purchasedProduct?.productSubscriptionPlan?.id,
          productId: purchasedProduct?.product?.id,
          productName: purchasedProduct?.product?.productName,
          productHeading: purchasedProduct?.product?.productHeading,
          productCoverPath: purchasedProduct?.product?.productCoverPath,
          status: purchasedProduct?.status,
          accessUntil: formatDate(purchasedProduct?.accessUntil),
          child: `${purchasedProduct?.student?.firstName} ${purchasedProduct?.student?.lastName}`,
          subscriptionId: purchasedProduct?.otherInfo
            ? purchasedProduct?.otherInfo?.subscriptionId
            : purchasedProduct?.gatewaySubscriptionId,
        })),
        ...data.indPurchasedBundles.map((purchasedBundle) => ({
          ...purchasedBundle.bundleSubscriptionPlan,
          id: purchasedBundle.id,
          type: "Bundle",
          productSubscriptionPlanId: purchasedBundle.bundleSubscriptionPlan.id,
          productId: purchasedBundle.bundle.id,
          productName: purchasedBundle.bundle.bundleName,
          productHeading: purchasedBundle.bundle.bundleHeading,
          productCoverPath: purchasedBundle.bundle.bundleCoverPath,
          status: purchasedBundle.status,
          accessUntil: formatDate(purchasedBundle.accessUntil),
          child: `${purchasedBundle.student.firstName} ${purchasedBundle.student.lastName}`,
          subscriptionId: purchasedBundle.otherInfo
            ? purchasedBundle.otherInfo.subscriptionId
            : purchasedBundle.gatewaySubscriptionId,
        })),
      ]);
    },
    onError: () => {
      loadingCallback(false, "");
    },
    fetchPolicy: "cache-and-network",
  });

  const [getRazorpaySubscriptionInvoices] = useLazyQuery(
    RAZORPAY_SUBSCRIPTION_INVOICES,
    {
      onCompleted: (data) => {
        loadingCallback(false, "");
        setInvoices(
          data.subscriptionInvoicesRazorpay.map((invoice) => ({
            ...invoice,
            amount: invoice.amount / 100,
            amount_paid: invoice.amount_paid / 100,
            amount_due: invoice.amount_due / 100,
            paid_at: formatInvoiceDate(invoice.paid_at),
          }))
        );
      },
      onError: () => {
        loadingCallback(false, "");
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const [getStripeSubscriptionInvoices] = useLazyQuery(
    STRIPE_SUBSCRIPTION_INVOICES,
    {
      onCompleted: (data) => {
        loadingCallback(false, "");
        setInvoices(
          data.subscriptionInvoicesStripe.map((invoice) => ({
            ...invoice,
            amount: invoice.amount / 100,
            amount_paid: invoice.amount_paid / 100,
            amount_due: invoice.amount_due / 100,
            paid_at: formatInvoiceDate(invoice.paid_at),
          }))
        );
      },
      onError: () => {
        loadingCallback(false, "");
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const [cancelRazorpaySubscription] = useMutation(CANCELRAZORPAYSUBSCRIPTION, {
    onCompleted: (data) => {
      loadingCallback(false, "");
      let subscriptionsCopy = [...subscriptions];
      const index = subscriptionsCopy.findIndex(
        (subs) => subs.id === data.cancelRazorpaySubscription.id
      );
      if (index > -1) {
        subscriptionsCopy[index] = {
          ...subscriptionsCopy[index],
          status: data.cancelRazorpaySubscription.status,
        };
        setSubscriptions(subscriptionsCopy);
      }
      setSubscription({});
      setIsRenew(false);
    },
    onError: () => {
      loadingCallback(false, "");
    },
  });

  const [cancelStripeSubscription] = useMutation(CANCELSTRIPESUBSCRIPTION, {
    onCompleted: (data) => {
      loadingCallback(false, "");
      let subscriptionsCopy = [...subscriptions];
      const index = subscriptionsCopy.findIndex(
        (subs) => subs.id === data.cancelStripeSubscription.id
      );
      if (index > -1) {
        subscriptionsCopy[index] = {
          ...subscriptionsCopy[index],
          status: data.cancelStripeSubscription.status,
        };
        setSubscriptions(subscriptionsCopy);
      }
      setSubscription({});
      setIsRenew(false);
    },
    onError: () => {
      loadingCallback(false, "");
    },
  });

  const [razorpayCheckoutSuccess] = useMutation(RAZORPAYCHECKOUTSUCCESS, {
    onCompleted: (data) => {
      loadingCallback(false, "");
      const subscriptionsCopy = [...subscriptions];
      const purchased_p = data.razorpayCheckoutSuccess;
      subscriptionsCopy.push(
        purchased_p.product
          ? {
              ...purchased_p.productSubscriptionPlan,
              id: purchased_p.id,
              type: "Course",
              productSubscriptionPlanId: purchased_p.productSubscriptionPlan.id,
              productId: purchased_p.product.id,
              productName: purchased_p.product.productName,
              productHeading: purchased_p.product.productHeading,
              productCoverPath: purchased_p.product.productCoverPath,
              status: purchased_p.status,
              accessUntil: formatDate(purchased_p.accessUntil),
              child: `${purchased_p.student.firstName} ${purchased_p.student.lastName}`,
              subscriptionId: purchased_p.otherInfo
                ? purchased_p.otherInfo.subscriptionId
                : "",
            }
          : {
              ...purchased_p.bundleSubscriptionPlan,
              id: purchased_p.id,
              type: "Bundle",
              productSubscriptionPlanId: purchased_p.bundleSubscriptionPlan.id,
              productId: purchased_p.bundle.id,
              productName: purchased_p.bundle.bundleName,
              productHeading: purchased_p.bundle.bundleHeading,
              productCoverPath: purchased_p.bundle.bundleCoverPath,
              status: purchased_p.status,
              accessUntil: formatDate(purchased_p.accessUntil),
              child: `${purchased_p.student.firstName} ${purchased_p.student.lastName}`,
              subscriptionId: purchased_p.otherInfo
                ? purchased_p.otherInfo.subscriptionId
                : "",
            }
      );
      setSubscriptions(subscriptionsCopy);
    },
    onError: () => {
      loadingCallback(false, "");
    },
  });

  const [updatePurchasedBundle] = useMutation(UPDATEINDPURCHASEDBUNDLE, {
    onCompleted: (data) => {
      loadingCallback(false, "");
      let subscriptionsCopy = [...subscriptions];
      const index = subscriptionsCopy.findIndex(
        (subs) => subs.id === data.updateIndPurchasedBundle.id
      );
      if (index > -1) {
        subscriptionsCopy[index] = {
          ...subscriptionsCopy[index],
          status: data.updateIndPurchasedBundle.status,
        };
        setSubscriptions(subscriptionsCopy);
      }
      setSubscription({});
      setIsRenew(false);
    },
    onError: () => {
      loadingCallback(false, "");
    },
  });

  const [updatePurchasedProduct] = useMutation(UPDATEINDPURCHASEDPRODUCT, {
    onCompleted: (data) => {
      loadingCallback(false, "");
      let subscriptionsCopy = [...subscriptions];
      const index = subscriptionsCopy.findIndex(
        (subs) => subs.id === data.updateIndPurchasedProduct.id
      );
      if (index > -1) {
        subscriptionsCopy[index] = {
          ...subscriptionsCopy[index],
          status: data.updateIndPurchasedProduct.status,
        };
        setSubscriptions(subscriptionsCopy);
      }
      setSubscription({});
      setIsRenew(false);
    },
    onError: () => {
      loadingCallback(false, "");
    },
  });

  const [createRazorpaySubscription] = useMutation(CREATESUBSCRIPTION, {
    onCompleted: (data) => {
      const product = subscriptions.find((subs) => subs.id === subscription.id);
      let options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
        subscription_id: data.createRazorpaySubscription.id,
        name: product.productName,
        description: product.productHeading,
        image: product.productCoverPath,
        prefill: {
          name: `${user.firstName} ${user.lastName}`,
          ...(!!user.email ? { email: user.email } : {}),
          ...(!!user.contact ? { contact: user.contact } : {}),
        },
        theme: {
          color: "#3399cc",
        },
        handler: function (response) {
          loadingCallback(true, "");
          const data = {
            parent: {
              firstName: !isChildHasNoParent ? user.firstName : "",
              lastName: !isChildHasNoParent ? user.lastName : "",
              email: !isChildHasNoParent ? user.email : "",
              phone: !isChildHasNoParent ? user.contact : "",
            },
            child: {
              firstName: isChildHasNoParent ? user.firstName : child.firstName,
              lastName: isChildHasNoParent ? user.lastName : child.lastName,
              email: isChildHasNoParent ? user.email : child.email,
              phone: isChildHasNoParent ? user.phone : child.phone,
            },
            product: {
              id: product.productId,
              productType: product.type,
              productSubscriptionPlanId: product.productSubscriptionPlanId,
              frequency: product.frequency,
            },
            subscription: {
              id: response.razorpay_subscription_id,
            },
            isOrphan: isChildHasNoParent,
          };
          razorpayCheckoutSuccess({
            variables: {
              data,
            },
          });
          let type = subscription.type;
          if (type === "Course") {
            updatePurchasedProduct({
              variables: {
                where: {
                  id: subscription.id,
                },
                data: {
                  status: "Expired",
                },
              },
            });
          }
          if (type === "Bundle") {
            updatePurchasedBundle({
              variables: {
                where: {
                  id: subscription.id,
                },
                data: {
                  status: "Expired",
                },
              },
            });
          }
        },
        redirect: false,
      };
      let rzp1 = new window.Razorpay(options);
      rzp1.open();
      setSubscription({});
      setIsRenew(false);
    },
    onError: () => {
      loadingCallback(false, "");
    },
  });

  const [createStripeCheckout] = useMutation(STRIPE_CHECKOUT, {
    onCompleted: (data) => {
      loadingCallback(false, "");
      window.location.href = data.checkoutProduct.url;
    },
    onError: (err) => {
      loadingCallback(false, "");
    },
  });

  useEffect(() => {
    loadingCallback(true, `Fetching your subscriptions.`);
    getSubscriptions({
      variables: {
        productWhere: {
          customer: {
            id: { equals: indParentId },
          },
        },
        bundleWhere: {
          customer: {
            id: { equals: indParentId },
          },
        },
        productOrderBy: {
          createdAt: "desc",
        },
        bundleOrderBy: {
          createdAt: "desc",
        },
      },
    });
  }, [getSubscriptions, loadingCallback, indParentId, productType]);

  const onConfirm = () => {
    if (!isRenew && subscription.gatewayProvider === "razorpay") {
      loadingCallback(true, "Cancelling the subscription...");
      cancelRazorpaySubscription({
        variables: {
          where: {
            productId: subscription.productId,
            subscriptionId: subscription.subscriptionId,
            type: subscription.type,
          },
        },
      });
    }
    if (!isRenew && subscription.gatewayProvider === "stripe") {
      loadingCallback(true, "Cancelling the subscription...");
      cancelStripeSubscription({
        variables: {
          where: {
            productId: subscription.productId,
            subscriptionId: subscription.subscriptionId,
            type: subscription.type,
          },
        },
      });
    }
    setOpen(false);
    if (isRenew && subscription.gatewayProvider !== "stripe") {
      if (isChildHasNoParent) {
        setOpenChildModal(false);
        loadingCallback(true, "Checking out...");
        createRazorpaySubscription({
          variables: {
            data: {
              planId: subscription.gatewayPlanId,
              frequency: subscription.frequency,
            },
          },
        });
      }
      if (!isChildHasNoParent && (!!child.firstName || !!child.lastName)) {
        setOpenChildModal(false);
        loadingCallback(true, "Checking out...");
        createRazorpaySubscription({
          variables: {
            data: {
              planId: subscription.gatewayPlanId,
              frequency: subscription.frequency,
            },
          },
        });
      }
      if (!isChildHasNoParent && (!!!child.firstName || !!!child.lastName)) {
        setError(
          isNewChild
            ? "Fill the chld details."
            : "Please select child to checkout."
        );
      }
    }
    if (isRenew && subscription.gatewayProvider === "stripe") {
      loadingCallback(true, "Checking out...");
      const data = {
        parent: {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          phone: user.phone,
        },
        child: {
          firstName: child.firstName,
          lastName: child.lastName,
        },
        product: {
          id: subscription.productId,
          productType: subscription.type,
          productSubscriptionPlanId: subscription.id,
          frequency: subscription.frequency,
        },
        isNewUser: false,
      };
      createStripeCheckout({
        variables: {
          data: {
            customer_email: user.email,
            lineItems: { product: subscription.gatewayPlanId },
            successUrl: `https://${window.location.host}/checkoutsuccess/?success=true`,
            cancelUrl: `https://${window.location.host}/checkouterror/?failed=true`,
            metadata: JSON.stringify(data),
          },
        },
      });
    }
  };

  const cancelSubscription = (data) => {
    setModalText({
      title: "Cancel subscription.",
      description: "Are you sure you want to cancel the subscription.",
    });
    setOpen(true);
    setIsRenew(false);
    setSubscription(data);
  };

  const renewSubscription = (data) => {
    setSubscription(data);
    const subscriptionChild = children.find(
      (c) => `${c.firstName} ${c.lastName}` === data.child
    );
    setChild(subscriptionChild);
    setOpenChildModal(true);
    setIsRenew(true);
  };

  const viewInvoices = (id, isRazorpay = true) => {
    loadingCallback(true, "Fetching the invoices...");
    setShowInvoice(true);
    if (isRazorpay) {
      setIsRazorpay(true);
      getRazorpaySubscriptionInvoices({
        variables: {
          where: {
            id,
          },
        },
      });
      return true;
    }
    setIsRazorpay(false);
    getStripeSubscriptionInvoices({
      variables: {
        where: {
          subscriptionId: id,
        },
      },
    });
  };

  const getStatusValue = (value) => {
    if (value === "Active") {
      return {
        label: value,
        style: {
          color: "rgb(34, 154, 22)",
          fontWeight: 700,
          background: "rgba(84, 214, 44, 0.16)",
          borderRadius: "6px",
          textTransform: "capitalize",
          fontSize: "0.75rem",
          border: "none",
          height: "22px",
          minWidth: "22px",
        },
      };
    }
    if (value === "Cancelled") {
      return {
        label: value,
        style: {
          color: "rgb(183, 33, 54)",
          fontWeight: 700,
          background: "rgba(255, 72, 66, 0.16)",
          borderRadius: "6px",
          textTransform: "capitalize",
          fontSize: "0.75rem",
          border: "none",
          height: "22px",
          minWidth: "22px",
        },
      };
    }
    return {
      label: value,
      style: {
        color: "rgb(183, 33, 54)",
        fontWeight: 700,
        background: "rgba(255, 72, 66, 0.16)",
        borderRadius: "6px",
        textTransform: "capitalize",
        fontSize: "0.75rem",
        border: "none",
        height: "22px",
        minWidth: "22px",
      },
    };
  };

  const getPaidStatusValue = (value) => {
    if (value === "paid") {
      return {
        label: value,
        style: {
          color: "rgb(34, 154, 22)",
          fontWeight: 700,
          background: "rgba(84, 214, 44, 0.16)",
          borderRadius: "6px",
          textTransform: "capitalize",
          fontSize: "0.75rem",
          border: "none",
          height: "22px",
          minWidth: "22px",
        },
      };
    }
    if (value === "issued") {
      return {
        label: value,
        style: {
          color: "rgb(183, 129, 3)",
          fontWeight: 700,
          background: "rgba(255, 193, 7, 0.16)",
          borderRadius: "6px",
          textTransform: "capitalize",
          fontSize: "0.75rem",
          border: "none",
          height: "22px",
          minWidth: "22px",
        },
      };
    }
    if (value === "cancelled" || value === "expired") {
      return {
        label: value,
        style: {
          color: "rgb(183, 33, 54)",
          fontWeight: 700,
          background: "rgba(255, 72, 66, 0.16)",
          borderRadius: "6px",
          textTransform: "capitalize",
          fontSize: "0.75rem",
          border: "none",
          height: "22px",
          minWidth: "22px",
        },
      };
    }
    return {
      label: value,
      style: {
        color: "rgb(183, 33, 54)",
        fontWeight: 700,
        background: "rgba(255, 72, 66, 0.16)",
        borderRadius: "6px",
        textTransform: "capitalize",
        fontSize: "0.75rem",
        border: "none",
        height: "22px",
        minWidth: "22px",
      },
    };
  };

  return (
    <Box>
      <Typography
        component="h2"
        variant="h6"
        color="primary"
        noWrap
        sx={{
          flexGrow: 1,
          fontWeight: "bold",
          margin: "0 0 30px",
          color: "#424770",
        }}
      >
        {showInvoice ? (
          <>
            <IconButton aria-label="back" onClick={() => setShowInvoice(false)}>
              <ArrowBackIcon />
            </IconButton>{" "}
            Invoice
          </>
        ) : (
          "Subscriptions"
        )}
      </Typography>
      {showInvoice ? (
        <DataTable
          visibleFields={[
            "id",
            "amount",
            "amount_paid",
            "amount_due",
            "paid_at",
            "status",
            "action",
          ]}
          searchColumns={[
            { id: "11", value: "amount", name: "Amount" },
            { id: "12", value: "amount_paid", name: "Amount paid" },
            { id: "13", value: "amount_due", name: "Amount due" },
            { id: "14", value: "paid_at", name: "Pait at" },
          ]}
          columns={[
            { field: "id", headerName: "Id", flex: 0.5 },
            {
              field: "amount",
              headerName: "Total Amount",
              renderCell: (params) => {
                return (
                  <Typography
                    component="p"
                    color="primary"
                    noWrap
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexGrow: 1,
                      color: "#424770",
                    }}
                  >
                    {isRazorpay ? <CurrencyRupeeIcon /> : <AttachMoneyIcon />}
                    <Box>{params.amount}</Box>
                  </Typography>
                );
              },
              flex: 1,
            },
            {
              field: "amount_paid",
              headerName: "Amount Paid",
              renderCell: (params) => {
                return (
                  <Typography
                    component="p"
                    color="primary"
                    noWrap
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexGrow: 1,
                      color: "#424770",
                    }}
                  >
                    {isRazorpay ? <CurrencyRupeeIcon /> : <AttachMoneyIcon />}
                    <Box>{params.amount_paid}</Box>
                  </Typography>
                );
              },
              flex: 1,
            },
            {
              field: "amount_due",
              headerName: "Amount Due",
              renderCell: (params) => {
                return (
                  <Typography
                    component="p"
                    color="primary"
                    noWrap
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexGrow: 1,
                      color: "#424770",
                    }}
                  >
                    {isRazorpay ? <CurrencyRupeeIcon /> : <AttachMoneyIcon />}
                    <Box>{params.amount_due}</Box>
                  </Typography>
                );
              },
              flex: 1,
            },
            { field: "paid_at", headerName: "Paid On", flex: 1 },
            {
              field: "status",
              headerName: "status",
              renderCell: (params) => {
                return (
                  <Chip
                    variant="outlined"
                    {...getPaidStatusValue(params.status)}
                  />
                );
              },
              flex: 1,
            },
            {
              field: "action",
              headerName: "Action",
              sortable: false,
              renderCell: (row) => {
                return (
                  <Link
                    underline="none"
                    title="open invoice"
                    target="_self"
                    href={row.short_url}
                  >
                    <LaunchIcon />
                  </Link>
                );
              },
              flex: 0.5,
            },
          ]}
          originalRows={invoices}
          noDataMsg="No invoice found."
        />
      ) : (
        <DataTable
          visibleFields={[
            "planName",
            "planShortDescription",
            "frequency",
            "accessUntil",
            "status",
            "invoices",
            "action",
            ...(isChildHasNoParent ? [] : ["child"]),
          ]}
          searchColumns={[
            { id: "11", value: "planName", name: "Plan name" },
            {
              id: "12",
              value: "planShortDescription",
              name: "Plan short description",
            },
            { id: "14", value: "frequency", name: "Frequency" },
            { id: "15", value: "accessUntil", name: "Access Until" },
            ...(isChildHasNoParent
              ? []
              : [{ id: "13", value: "child", name: "Child" }]),
          ]}
          columns={[
            { field: "planName", headerName: "Plan Name", flex: 0.5 },
            {
              field: "planShortDescription",
              headerName: "Plan Short Description",
              flex: 1.5,
            },
            {
              field: "child",
              headerName: "Child",
              flex: 1,
            },
            { field: "frequency", headerName: "Frequency", flex: 0.5 },
            {
              field: "accessUntil",
              headerName: "Access Until",
              type: "date",
              flex: 0.5,
            },
            {
              field: "status",
              headerName: "Status",
              renderCell: (row) => {
                return (
                  <Chip variant="outlined" {...getStatusValue(row.status)} />
                );
              },
              flex: 0.5,
            },
            {
              field: "invoices",
              headerName: "View Invoice",
              renderCell: (row) => {
                return (
                  <IconButton
                    aria-label="back"
                    onClick={() => {
                      viewInvoices(
                        row.subscriptionId,
                        row?.gatewayProvider
                          ? row.gatewayProvider !== "stripe"
                          : true
                      );
                    }}
                  >
                    <DescriptionRoundedIcon sx={{ color: "#1890ff" }} />
                  </IconButton>
                );
              },
              flex: 0.5,
            },
            {
              field: "action",
              headerName: "Action",
              sortable: false,
              renderCell: (row) => {
                return (
                  <Button
                    color={row.status === "Active" ? "error" : "primary"}
                    onClick={(event) => {
                      if (row.status === "Active") cancelSubscription(row);
                      if (
                        row.status === "Cancelled" ||
                        row.status === "Expired"
                      )
                        renewSubscription(row);
                    }}
                  >
                    {row.status === "Active" ? "Cancel" : "Buy Again"}
                  </Button>
                );
              },
              flex: 1,
            },
          ]}
          originalRows={subscriptions}
          noDataMsg="It seems that you haven't purchased any products."
        />
      )}
      <ModalBox
        title={modalText.title}
        description={modalText.description}
        isFooter={true}
        confirmButtonText="Yes"
        cancelButtonText="No"
        open={open}
        onConfirm={onConfirm}
        isDeleteButton={true}
        onCancel={() => {
          setOpen(false);
          setSubscription({});
          setIsRenew(false);
        }}
      />
      <ModalBox
        title={subscription.productName}
        size="md"
        description={<CheckoutFormWithoutChild subscription={subscription} />}
        errorText={error}
        isFooter={true}
        confirmButtonText="Checkout"
        cancelButtonText="Cancel"
        open={openChildModal}
        onConfirm={onConfirm}
        isDeleteButton={false}
        onCancel={() => {
          setOpenChildModal(false);
          setSubscription({});
          setIsRenew(false);
          setError("");
        }}
      />
    </Box>
  );
};
