import React from "react";
import {
  styled,
  Toolbar,
  List,
  IconButton,
  Divider,
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import HomeIcon from "@mui/icons-material/Home";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import StoreIcon from "@mui/icons-material/Store";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { NavLink, useLocation } from "react-router-dom";
import "./AppDrawer.css";

const ListItemButtonStyle = styled(ListItemButton)`
  :hover,
  &.Mui-selected {
    background-color: rgba(0, 176, 255, 0.16);
    color: #ffffff;
    &:hover {
      background-color: rgba(0, 176, 255, 0.16);
      color: #ffffff;
    }
  }
`;

export const AppDrawer = ({ open, toggleDrawer, listItems }) => {
  let location = useLocation();
  const icons = {
    home: <HomeIcon color="secondary" />,
    books: <MenuBookIcon color="secondary" />,
    course: <MenuBookIcon color="secondary" />,
    store: <StoreIcon color="secondary" />,
    cart: <ShoppingCartIcon color="secondary" />,
    billing: <ReceiptLongIcon color="secondary" />,
    profile: <AccountBoxIcon color="secondary" />,
  };
  return (
    <MuiDrawer
      variant="temporary"
      open={open}
      onClose={toggleDrawer}
      BackdropProps={{ style: { backgroundColor: "rgba(66, 71, 112, 0.4)" } }}
    >
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          px: [1],
        }}
      >
        <IconButton onClick={toggleDrawer}>
          <ChevronLeftIcon color="primary" />
        </IconButton>
      </Toolbar>
      <Divider />
      <List
        component="nav"
        sx={{
          fontWeight: "600",
          color: "#000",
          fontFamily: "Glober",
          width: "265px",
        }}
      >
        {listItems.map((item, index) => (
          <ListItemButtonStyle
            key={item.id}
            selected={location.pathname === item.link}
            sx={{
              padding: 0,
            }}
          >
            <NavLink
              to={item.link}
              style={{
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
                padding: "8px 16px",
              }}
              onClick={toggleDrawer}
            >
              <ListItemIcon sx={{ minWidth: "auto", marginRight: 2 }}>
                {icons[item.icon]}
              </ListItemIcon>
              <ListItemText primary={item.name} className="list-item" />
            </NavLink>
          </ListItemButtonStyle>
        ))}
      </List>
    </MuiDrawer>
  );
};
