import * as React from "react";
import { Box, InputLabel, MenuItem, FormControl, Select } from "@mui/material";

export const SelectDropDown = ({
  label,
  value,
  name,
  options = [],
  styles,
  id = "",
  handleChange,
}) => {
  return (
    <Box sx={styles}>
      <FormControl fullWidth>
        <InputLabel id={id}>{label}</InputLabel>
        <Select
          labelId={id}
          value={value}
          name={name}
          label={label}
          onChange={handleChange}
        >
          {options.map((option) => (
            <MenuItem key={option.id} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
