import React from "react";
import { Box } from "@mui/material";

export const Logo = ({ logoURL }) => {
  return (
    <Box>
      <img
        src={!!logoURL ? logoURL : "https://www.dynos.io/logo-blue.svg"}
        style={{ width: "80px" }}
        alt="Org logo"
      />
    </Box>
  );
};
