import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Snackbar, Alert } from "@mui/material";
import { useStore } from "../../Store";
import { ChildCard } from "../../components/ChildCard";
import { india } from "../../config"
import { CREATE_CHILD_PASSWORD_AND_SEND_MAIL } from "../../graphql/mutations";
import "./HomePage.css";

export const HomePage = ({ user }) => {

  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [open, setOpen] = useState("");
  const [success, setSuccess] = useState(null);

  const navigate = useNavigate();
  const children = useStore((state) => state.children);

  const childAction = (id) => {
    navigate(`/child/${id}`);
  };

  const [sendChildPasswordMail] = useMutation(CREATE_CHILD_PASSWORD_AND_SEND_MAIL, {
    onCompleted: (data) => {
      handleClick()
      setSuccess(true)
      setSnackbarMsg(`${children[0].firstName} ${children[0].lastName} username and password sent to email.`)
      localStorage.removeItem("isNewChild")
    },
    onError: (e) => {
      handleClick()
      setSuccess(false)
      setSnackbarMsg(`Error while sending ${children[0].firstName} ${children[0].lastName} username and password. Visit profile page to reset child's password`)
      localStorage.removeItem("isNewChild")
    },
  });

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const colors = [
    {
      id: 1,
      name: "color-1",
    },
    {
      id: 2,
      name: "color-2",
    },
    {
      id: 3,
      name: "color-3",
    },
    {
      id: 4,
      name: "color-4",
    },
    {
      id: 5,
      name: "color-5",
    },
    {
      id: 6,
      name: "color-6",
    },
    {
      id: 7,
      name: "color-7",
    },
    {
      id: 8,
      name: "color-8",
    },
    {
      id: 9,
      name: "color-9",
    },
    {
      id: 10,
      name: "color-10",
    },
  ];

  useEffect(() => {
    const isNewChild = localStorage.getItem("isNewChild")
    if(Boolean(isNewChild) && children && user.email) {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      sendChildPasswordMail({
        variables: {
          data: {
            email: user.email,
            pFirstName: user.firstName,
            pLastName: user.lastName,
            cUserName: children[0].username,
            cFirstName: children[0].firstName,
            cLastName: children[0].lastName,
            isIndia: timeZone === india
          }
        }
      })
    }
  }, [children, user.email])

  return (
    <>
      <Box className="welcome-text" sx={{ margin: "0 0 20px" }}>
        Hello{" "}
        <Box as="span">
          {user.firstName} {user.lastName}
        </Box>
        ! Welcome to Dynos.
      </Box>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        justifyContent="flex-start"
        alignItems="stretch"
      >
        {children.map((child, index) => (
          <Grid item xs={12} sm={6} md={4} key={child.id}>
            <ChildCard
              user={child}
              onclick={() => childAction(child.id)}
              color={colors[Math.floor(Math.random() * 9) + 1]}
            />
          </Grid>
        ))}
      </Grid>
      <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={success ? "success" : "error"}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </>
  );
};
