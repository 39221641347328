import React from "react";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
  InputAdornment,
  FormHelperText,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export const FormPassword = ({
  label,
  showPassword = false,
  placeholder,
  value,
  inputOnChange,
  name,
  errorMessage = "",
  toggleShowPassword,
  formProps,
  styles,
  autoFocus
}) => {
  return (
    <>
      <FormControl required sx={styles} variant="outlined" {...formProps}>
        <InputLabel htmlFor="password">{label}</InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          type={showPassword ? "text" : "password"}
          value={value}
          name={name}
          onChange={inputOnChange}
          placeholder={placeholder}
          fullWidth
          autoFocus={autoFocus}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={toggleShowPassword}
                onMouseDown={toggleShowPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label={label}
        />
        {formProps?.error && (
          <FormHelperText sx={{ margin: "5px 0 0" }}>
            {errorMessage}
          </FormHelperText>
        )}
      </FormControl>
    </>
  );
};
