import React, { useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Paper, Box, Grid, Button, Typography } from "@mui/material";
import { FormInput } from "../../components/FormInput";
import { FormPassword } from "../../components/FormPassword";

const BorderBox = styled(Box)`
  display: flex;
  align-items: center;
  text-align: center;
  color: #4b4b4b;
  font-size: 17px;
  font-weight: 300;
  line-height: 23px;
  margin: 24px 0 20px;
  &:before {
    margin-right: 0.5em;
  }
  &:after {
    margin-left: 0.5em;
  }
  &:after,
  &:before {
    content: "";
    flex: 1;
    border-bottom: 1px solid #eaeaea;
  }
`;

export const SignUpPage = ({ loadingCallback }) => {
  const [user, setUser] = useState({
    firstName: "",
    familyName: "",
    email: "",
    phone: "",
  });
  const [countryCode, setCountryCode] = useState("+91");
  const [userNewPassword, setUserNewPassword] = useState("");
  const [userRepeatPassword, setUserRepeatPassword] = useState("");
  const [isPasswordError, setIsPasswordError] = useState(false);
  const [isRepeatPasswordError, setIsRepeatPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const onChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };
  const onPasswordChange = (e) => {
    if (e.target.name === "new_password") {
      setUserNewPassword(e.target.value);
      if (e.target.value.length < 8) {
        setIsPasswordError(true);
      } else {
        setIsPasswordError(false);
      }
    }
    if (e.target.name === "new_password_again") {
      setUserRepeatPassword(e.target.value);
      if (
        e.target.value.length < 8 ||
        userNewPassword.trim() !== e.target.value
      ) {
        setIsRepeatPasswordError(true);
      } else {
        setIsRepeatPasswordError(false);
      }
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // try {
    //   const { user } = await Auth.signUp({
    //       username,
    //       password,
    //       attributes: {
    //           email,          // optional
    //           phone_number,   // optional - E.164 number convention
    //           // other custom attributes
    //       }
    //   });
    //   console.log(user);
    // } catch (error) {
    //     console.log('error signing up:', error);
    // }
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={5} lg={6}></Grid>
      <Grid item xs={12} sm={7} lg={6}>
        <Paper
          sx={{
            // marginTop: 8,
            display: "flex",
            flexDirection: "column",
            padding: 5,
          }}
        >
          <Typography
            component="h1"
            variant="h5"
            sx={{
              color: "#323232",
              fontWeight: "bold",
              fontSize: "2rem",
              margin: "0 0 10px",
            }}
          >
            Create an account
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            sx={{ display: "flex", color: "#959595", fontWeight: "bold" }}
          >
            Already have an account? &nbsp;
            <NavLink to={`/login`}>
              <Typography paragraph={true}>Sign in</Typography>
            </NavLink>
          </Typography>
          <Box>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ color: "#505050", fontWeight: "bold" }}
            >
              Sign up with social
            </Typography>
            <Box
              component="section"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                paddingTop: "10px",
              }}
            >
              <Button
                variant="outlined"
                sx={{ textTransform: "none", color: "#323232" }}
              >
                <img
                  src="/img/g-logo.png"
                  alt="Google social"
                  className="SocialButton--round--google-img"
                  aria-label="Google social"
                  width="24px"
                />
                &nbsp; Signup with Google
              </Button>
              <Button
                variant="outlined"
                sx={{ textTransform: "none", color: "#323232" }}
              >
                <img
                  src="/img/facebook.png"
                  alt="Facebook social"
                  className="SocialButton--round--facebook-img"
                  aria-label="Facebook social"
                  width="24px"
                />
                &nbsp; Signup with Facebook
              </Button>
            </Box>
            <Box component="section">
              <BorderBox component="div">Or</BorderBox>
              <Typography
                variant="body1"
                gutterBottom
                sx={{ color: "#505050", fontWeight: "bold" }}
              >
                Sign up with email or phone
              </Typography>
            </Box>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 3 }}
            >
              <FormInput
                label="First Name"
                name="firstName"
                type="text"
                placeholder=""
                inputOnChange={onChange}
                value={user.firstName}
                styles={{
                  margin: "0 0 20px",
                  width: "100%",
                }}
                isRequired={true}
              />
              <FormInput
                label="Last Name"
                name="lastName"
                type="text"
                placeholder=""
                inputOnChange={onChange}
                value={user.familyName}
                styles={{
                  margin: "0 0 20px",
                  width: "100%",
                }}
                isRequired={true}
              />
              <FormInput
                label="Email"
                name="email"
                type="text"
                placeholder=""
                inputOnChange={onChange}
                value={user.email}
                styles={{
                  margin: "0 0 30px",
                  width: "100%",
                }}
                readOnly={true}
              />
              <FormPassword
                label="New Password"
                name="new_password"
                showPassword={showPassword}
                toggleShowPassword={() => setShowPassword(!showPassword)}
                placeholder=""
                inputOnChange={onPasswordChange}
                value={userNewPassword}
                formProps={{ error: isPasswordError }}
                styles={{
                  padding: "0 0 20px",
                  width: "100%",
                }}
                errorMessage={
                  <>
                    <Typography
                      paragraph={true}
                      sx={{
                        margin: "0",
                        color: /[a-z]/.test(userNewPassword) ? "green" : "red",
                      }}
                    >
                      Password must contain a lower case letter.
                    </Typography>
                    <Typography
                      paragraph={true}
                      sx={{
                        margin: "0",
                        color: /[A-Z]/.test(userNewPassword) ? "green" : "red",
                      }}
                    >
                      Password must contain an upper case letter.
                    </Typography>
                    <Typography
                      paragraph={true}
                      sx={{
                        margin: "0",
                        color: /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(
                          userNewPassword
                        )
                          ? "green"
                          : "red",
                      }}
                    >
                      Password must contain a special character.
                    </Typography>
                    <Typography
                      paragraph={true}
                      sx={{
                        margin: "0",
                        color: /\d/.test(userNewPassword) ? "green" : "red",
                      }}
                    >
                      Password must contain a number.
                    </Typography>
                    <Typography
                      paragraph={true}
                      sx={{
                        margin: "0",
                        color: userNewPassword.length >= 8 ? "green" : "red",
                      }}
                    >
                      Password must contain at least 8 characters.
                    </Typography>
                  </>
                }
              />
              <FormPassword
                label="Enter New Password Again"
                name="new_password_again"
                showPassword={showPassword}
                toggleShowPassword={() => setShowPassword(!showPassword)}
                placeholder=""
                inputOnChange={onPasswordChange}
                value={userRepeatPassword}
                formProps={{ error: isRepeatPasswordError }}
                styles={{
                  padding: "0 0 20px",
                  width: "100%",
                }}
                errorMessage={
                  <Typography
                    paragraph={true}
                    sx={{
                      margin: "0",
                      color: "red",
                    }}
                  >
                    Your password should match with the above one.
                  </Typography>
                }
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, textTransform: "none", fontWeight: "bold" }}
              >
                Sign Up
              </Button>
            </Box>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};
