import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { Box, Typography } from "@mui/material";
import { PURCHASED_BOOKS_APPS } from "../../graphql/queries";
import { ProductCard } from "../../components/ProductCard";
import { ViewToggleButton } from "../../components/ViewToggleButton";
import { useStore } from "../../Store";
import { AppCard } from "../../components/AppCard";

export const MyBooksPage = ({ loadingCallback, productType = "Book" }) => {
  const user = useStore((state) => state.user);
  const [viewType, setViewType] = useState("grid");
  const [books, setBooks] = useState([]);
  const [apps, setApps] = useState([]);

  const [getPurchasedBooksAndApps] = useLazyQuery(PURCHASED_BOOKS_APPS, {
    onCompleted: (data) => {
      loadingCallback(false, "");
      setBooks(data.userPurchasedBooksAndApps.books);
      setApps(data.userPurchasedBooksAndApps.apps);
    },
    onError: () => {
      loadingCallback(false, "");
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (user.id) {
      loadingCallback(
        true,
        `Fetching your ${productType === "Book" ? "books" : "courses"}.`
      );
      getPurchasedBooksAndApps({
        variables: {
          where: {
            userId: user.id,
          },
        },
      });
    }
  }, [getPurchasedBooksAndApps, loadingCallback, user, productType]);

  const handleViewChange = (e) => {
    setViewType(e.target.value);
  };

  return (
    <Box>
      <Typography
        sx={{
          fontWeight: "500",
          fontSize: { xs: "1.5rem", md: "2.5rem" },
          textAlign: "center",
          margin: "3rem 0",
        }}
      >
        My Apps
      </Typography>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
          gap: "1rem",
          ...(viewType === "grid"
            ? {
                alignItems: "stretch",
              }
            : {}),
        }}
      >
        {apps.map((app) => (
          <AppCard key={app.id} app={app} viewType={viewType} />
        ))}
        {apps.length === 0 && (
          <Box>
            <Typography
              sx={{ fontSize: 18, fontWeight: 500 }}
              color="text.secondary"
              gutterBottom
            >
              It seems that you haven't purchased apps.
            </Typography>
          </Box>
        )}
      </Box>
      {/* <Typography
        sx={{
          fontWeight: "500",
          fontSize: { xs: "1.5rem", md: "2.5rem" },
          textAlign: "center",
        }}
      >
        My Books
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "0 0 30px",
        }}
      >
        <ViewToggleButton
          viewType={viewType}
          handleViewChange={handleViewChange}
        />
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
          gap: "1rem",
          ...(viewType === "grid"
            ? {
                alignItems: "stretch",
              }
            : {}),
        }}
      >
        {books.map((book) => (
          <ProductCard key={book.id} product={book} viewType={viewType} />
        ))}
        {books.length === 0 && (
          <Box>
            <Typography
              sx={{ fontSize: 18, fontWeight: 500 }}
              color="text.secondary"
              gutterBottom
            >
              It seems that you haven't purchased courses.
            </Typography>
          </Box>
        )}
      </Box> */}
    </Box>
  );
};
