import React, { useState, useLayoutEffect } from "react";
import {
  Box,
  styled,
  Button,
  Toolbar,
  Typography,
  IconButton,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation } from "react-router-dom";
import { Logo } from "../Logo";
import { MenuIcon } from "../MenuIcon";
import { UserMenu } from "../UserMenu";
import "./Header.css";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  boxShadow: "none",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const getTitles = (location) => {
  switch (true) {
    case /myproducts/.test(location.pathname):
      return {
        title: `My Products`,
      };
    case /mycourses/.test(location.pathname):
      return {
        title: `My Courses`,
      };
    case /store/.test(location.pathname):
      return {
        title: `Store`,
      };
    case /mybooks/.test(location.pathname):
      return {
        title: `My Books`,
      };
    case /cart/.test(location.pathname):
      return {
        title: `Cart`,
      };
    case /course/.test(location.pathname):
      return {
        title: `Course`,
      };
    default:
      return {
        title: ``,
        subTitle: ``,
      };
  }
};

export const Header = ({
  open,
  toggleDrawer,
  user,
  isAuthenticated,
  Auth,
  isShow = true,
}) => {
  const [cuser, setCuser] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const pageTitle = getTitles(location);
  const login = () => {
    navigate("/login");
  };
  useLayoutEffect(() => {
    setCuser(user);
  }, [user]);
  return (
    <AppBar position="absolute" open={open}>
      <Toolbar
        sx={{
          pr: "24px", // keep right padding when drawer closed
          background: "#fff",
          borderBottom: "1px solid rgba(0, 176, 255, 0.4)",
        }}
      >
        {isShow && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "15px",
            }}
          >
            {open ? (
              <CloseIcon color="tertiary" />
            ) : (
              <div>
                <MenuIcon />
                <h2 className="menu-text">MENU</h2>
              </div>
            )}
          </IconButton>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          {!!pageTitle.title ? (
            <Typography
              component="h1"
              variant="h6"
              noWrap
              sx={{
                flexGrow: 1,
                fontWeight: "600",
                color: "#424770",
                fontSize: "24px",
              }}
            >
              {pageTitle.title}
            </Typography>
          ) : (
            <Button 
              variant="ghost"
              sx={{
                padding: 0,
                ":hover": {
                  background: "transparent",
                  outline: "none"
                },
                ":focus": {
                  background: "transparent",
                  outline: "none"
                },
                ":active": {
                  background: "transparent",
                  outline: "none"
                }
              }}
              onClick={() => navigate('/')}
              ><Logo logoURL="/img/ninja_logo.png" />
            </Button>
          )}
          {!isAuthenticated && isShow && (
            <Button variant="text" onClick={login}>
              <Typography color="primary" sx={{ fontWeight: "bold" }}>
                Login
              </Typography>
            </Button>
          )}
          {isAuthenticated && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <UserMenu
                username={`${cuser.firstName} ${cuser.lastName}`}
                Auth={Auth}
              />
            </Box>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};
