import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { FormInput } from "../FormInput";
import { FormPassword } from "../FormPassword";

export const AddChild = ({ child, onChange }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isPasswordError, setIsPasswordError] = useState(false);

  return (
    <Box
      sx={{
        width: "100%",
        margin: "0 auto",
        padding: "0",
        boxSizing: "border-box",
      }}
    >
      <form>
        <FormInput
          label="Enter First Name"
          name="firstName"
          type="text"
          placeholder="Enter First Name"
          inputOnChange={onChange}
          value={child?.firstName}
          styles={{
            display: "block",
            padding: "0 0 10px",
          }}
        />
        <FormInput
          label="Enter Last Name"
          name="lastName"
          type="text"
          placeholder="Enter Last Name"
          inputOnChange={onChange}
          value={child?.lastName}
          styles={{
            display: "block",
            padding: "0 0 10px",
          }}
        />
        <FormPassword
          label="Password"
          name="password"
          showPassword={showPassword}
          toggleShowPassword={() => setShowPassword(!showPassword)}
          placeholder=""
          inputOnChange={(e) => {
            onChange(e);
            if (e.target.value.length < 8) setIsPasswordError(true);
            else setIsPasswordError(false);
          }}
          value={child.password}
          formProps={{ error: isPasswordError }}
          styles={{
            padding: "0 0 20px",
            width: "100%",
          }}
          errorMessage={
            <>
              <Typography
                as="span"
                paragraph={false}
                sx={{
                  display: "block",
                  margin: "0",
                  color: /[a-z]/.test(child?.password) ? "green" : "red",
                }}
              >
                Password must contain a lower case letter.
              </Typography>
              <Typography
                as="span"
                paragraph={false}
                sx={{
                  display: "block",
                  margin: "0",
                  color: /[A-Z]/.test(child?.password) ? "green" : "red",
                }}
              >
                Password must contain an upper case letter.
              </Typography>
              <Typography
                as="span"
                paragraph={false}
                sx={{
                  display: "block",
                  margin: "0",
                  color: /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(
                    child?.password
                  )
                    ? "green"
                    : "red",
                }}
              >
                Password must contain a special character.
              </Typography>
              <Typography
                as="span"
                paragraph={false}
                sx={{
                  display: "block",
                  margin: "0",
                  color: /\d/.test(child?.password) ? "green" : "red",
                }}
              >
                Password must contain a number.
              </Typography>
              <Typography
                as="span"
                paragraph={false}
                sx={{
                  display: "block",
                  margin: "0",
                  color: child?.password?.length >= 8 ? "green" : "red",
                }}
              >
                Password must contain at least 8 characters.
              </Typography>
            </>
          }
        />
      </form>
    </Box>
  );
};
