import { gql } from "@apollo/client";

export const GETCUSTOMERPORTAL = gql`
  mutation CreateStripeCustomerPortal($data: StripeCustomerInput!) {
    createStripeCustomerPortal(data: $data) {
      url
    }
  }
`;

export const CHECKOUT = gql`
  mutation Checkout($data: CheckoutProductInput!) {
    checkoutProduct(data: $data) {
      type
      url
    }
  }
`;

export const UPDATEUSER = gql`
  mutation UpdateUser($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
    updateUser(data: $data, where: $where) {
      id
      firstName
      lastName
    }
  }
`;

export const CANCELRAZORPAYSUBSCRIPTION = gql`
  mutation CancelSubscription($where: RazorpaySubscriptionInput!) {
    cancelRazorpaySubscription(where: $where) {
      id
      status
    }
  }
`;

export const CANCELSTRIPESUBSCRIPTION = gql`
  mutation CancelStripeSubscription($where: StripeSubscriptionInput!) {
    cancelStripeSubscription(where: $where) {
      id
      status
    }
  }
`;

export const CREATESUBSCRIPTION = gql`
  mutation CreateSubscription($data: CreateRazorpaySubscriptionInput!) {
    createRazorpaySubscription(data: $data)
  }
`;

export const RAZORPAYCHECKOUTSUCCESS = gql`
  mutation RazorpayCheckoutSuccess($data: RazorpayCheckoutSuccessInput!) {
    razorpayCheckoutSuccess(data: $data)
  }
`;

export const UPDATEINDPURCHASEDPRODUCT = gql`
  mutation UpdateIndPurchasedProduct(
    $where: IndPurchasedProductWhereUniqueInput!
    $data: IndPurchasedProductUpdateInput!
  ) {
    updateIndPurchasedProduct(where: $where, data: $data) {
      id
      status
    }
  }
`;

export const UPDATEINDPURCHASEDBUNDLE = gql`
  mutation UpdateIndPurchasedBundle(
    $where: IndPurchasedBundleWhereUniqueInput!
    $data: IndPurchasedBundleUpdateInput!
  ) {
    updateIndPurchasedBundle(where: $where, data: $data) {
      id
      status
    }
  }
`;

export const INVITECHILD = gql`
  mutation InviteChild(
    $where: InviteChildWhereUniqueInput!
    $data: InviteChildUniqueInput!
  ) {
    inviteChild(where: $where, data: $data) {
      message
      user {
        id
        firstName
        lastName
        username
        otherInfo
      }
    }
  }
`;

export const RESETPASSWORD = gql`
  mutation ResetChildPassword($data: ResetChildPasswordUniqueInput!) {
    resetChildPassword(data: $data) {
      status
    }
  }
`;

export const STRIPE_CHECKOUT = gql`
  mutation CheckoutProduct($data: CheckoutProductInput!) {
    checkoutProduct(data: $data) {
      url
    }
  }
`;
export const CREATE_CHILD_PASSWORD_AND_SEND_MAIL = gql`
  mutation FirstChildPswSendEmailNL($data: firstChildPswSendEmailNLInput!) {
    firstChildPswSendEmailNL(data: $data)
  }
`;
