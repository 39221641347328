import React, { useState } from "react";
import { Box, Paper, Button, Typography, Grid, List, Snackbar, Alert } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useMutation } from "@apollo/client";
import { UserItem } from "../../components/UserItem";
import { ModalBox } from "../../components/ModalBox";
import { useStore } from "../../Store";
import {
  UPDATEUSER,
  INVITECHILD,
  RESETPASSWORD,
} from "../../graphql/mutations";
import { AddChild } from "../../components/AddChild";
import { FormPassword } from "../../components/FormPassword";
import { india } from "../../config"

export const ProfilePage = ({ loadingCallback }) => {
  const {user, userRole, children, updateUser, updateStates} = useStore((state) => state);
  const [error, setError] = useState("");
  const [openChildModal, setOpenChildModal] = useState(false);
  const [openPwdModal, setOpenPwdModal] = useState(false);
  const [child, setChild] = useState({
    firstName: "",
    lastName: "",
    password: "",
  });

  const [userToUpdate, setUserToUpdate] = useState({});

  const [pupilToChangePwd, setPupilToChangePwd] = useState({
    username: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isPasswordError, setIsPasswordError] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [open, setOpen] = useState("");
  const [isChildResetPswSuccess, setIsChildResetPswSuccess] = useState(null);


  const [updateUserInfo] = useMutation(UPDATEUSER, {
    onCompleted: (data) => {
      loadingCallback(false, "");
      setUserToUpdate({});
    },
    onError: () => {
      loadingCallback(false, "");
      setUserToUpdate({});
    },
  });

  const [inviteChild] = useMutation(INVITECHILD, {
    onCompleted: (data) => {
      loadingCallback(false, "");
      setChild({
        firstName: "",
        lastName: "",
      });
      updateStates({
        children: [...children, data.inviteChild.user],
      });
    },
    onError: () => {
      loadingCallback(false, "");
      setChild({
        firstName: "",
        lastName: "",
      });
    },
  });

  const [resetTempPassword] = useMutation(RESETPASSWORD, {
    onCompleted: (data) => {
      setIsChildResetPswSuccess(true)
      setSnackbarMsg("Password reset success")
      handleClick()
      loadingCallback(false, "");
      setPupilToChangePwd({
        username: "",
        password: "",
      });
    },
    onError: () => {
      setIsChildResetPswSuccess(false)
      setSnackbarMsg("Failed to reset password")
      handleClick()
      loadingCallback(false, "");
      setPupilToChangePwd({
        username: "",
        password: "",
      });
    },
  });

  const onChange = (e) => {
    updateUser({
      ...user,
      [e.target.name]: e.target.value,
    });
    setUserToUpdate({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeChild = (e, id) => {
    const childrenCopy = [...children];
    const index = childrenCopy.findIndex((pupil) => pupil.id === id);
    if (index > -1) {
      childrenCopy[index] = {
        ...childrenCopy[index],
        [e.target.name]: e.target.value,
      };
      updateStates({
        children: childrenCopy,
      });
      setUserToUpdate(childrenCopy[index]);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!!userToUpdate.id) {
      loadingCallback(true, "Updating user...");
      updateUserInfo({
        variables: {
          where: {
            id: userToUpdate.id,
          },
          data: {
            firstName: {set: userToUpdate.firstName},
            lastName: {set: userToUpdate.lastName},
          },
        },
      });
    }
  };

  const onCancel = () => {
    setOpenChildModal(false);
    setChild({
      firstName: "",
      lastName: "",
      password: "",
    });
    setError("");
  };

  const onConfirm = () => {
    if (
      !!child.firstName &&
      !!child.lastName &&
      !!child.password &&
      child.password.length >= 8
    ) {
      setOpenChildModal(false);
      loadingCallback(true, "Adding new child...");
      inviteChild({
        variables: {
          where: {
            email: user.email,
          },
          data: {
            firstName: child.firstName,
            lastName: child.lastName,
            pcode: child.password,
            role: "Student",
          },
        },
      });
    } else {
      setError("Fill the chld details.");
    }
  };

  const resetPassword = (pupil) => {
    setPupilToChangePwd({
      ...pupilToChangePwd,
      username: pupil.username,
    });
    setOpenPwdModal(true);
  };

  const onConfirmChangePassword = () => {
    if (pupilToChangePwd.password.length >= 8) {
      setOpenPwdModal(false);
      loadingCallback(true, "Resetting child password...");
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      resetTempPassword({
        variables: {
          data: {
            username: pupilToChangePwd.username,
            pcode: pupilToChangePwd.password,
            isIndia: timeZone === india
          },
        },
      });
    }
  };

  const onCancelChangePassword = () => {
    setOpenPwdModal(false);
    setPupilToChangePwd({
      username: "",
      password: "",
    });
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Box>
      <Typography
        component="h2"
        variant="h6"
        color="primary"
        noWrap
        sx={{
          flexGrow: 1,
          fontWeight: "bold",
          margin: "0 0 30px",
          color: "#424770",
        }}
      >
        Profile
      </Typography>
      <Paper
        sx={{
          borderRadius: "20px",
          boxShadow: "rgb(0 176 255 / 20%) 4px 16px 24px 0px",
          padding: { xs: "20px 10px", md: "20px" },
          border: "1px solid rgba(0, 176, 255, 0.4)",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: { xs: "space-between" },
            }}
          >
            <Typography sx={{ mb: 2 }} variant="h6" component="div">
              {userRole === "Student" ? "Student" : "Parent"}
            </Typography>
            {userRole === "Parent" && (
              <Button
                onClick={() => setOpenChildModal(true)}
                disabled={openChildModal}
              >
                <AddIcon /> Add New Child
              </Button>
            )}
          </Box>
          <List>
            <UserItem
              user={user}
              isChild={userRole === "Student"}
              onChange={onChange}
              onSubmit={onSubmit}
            />
          </List>
        </Box>
      </Paper>
      {children.length > 0 && (
        <Paper
          sx={{
            borderRadius: "20px",
            boxShadow: "rgb(0 176 255 / 20%) 4px 16px 24px 0px",
            padding: { xs: "20px 10px", md: "20px" },
            border: "1px solid rgba(0, 176, 255, 0.4)",
            margin: "20px 0 0",
          }}
        >
          <Box>
            <Grid item xs={12}>
              <Typography sx={{ mb: 2 }} variant="h6" component="div">
                Children
              </Typography>
            </Grid>
            <List>
              {children.map((pupil) => (
                <UserItem
                  key={pupil.id}
                  user={pupil}
                  isChild={true}
                  showResetButton={true}
                  onChange={(e) => onChangeChild(e, pupil.id)}
                  onSubmit={onSubmit}
                  resetPassword={() => resetPassword(pupil)}
                />
              ))}
            </List>
          </Box>
        </Paper>
      )}
      <ModalBox
        title="New Child"
        size="md"
        description={
          <AddChild
            child={child}
            onChange={(e) => {
              setChild({
                ...child,
                [e.target.name]: e.target.value,
              });
            }}
          />
        }
        errorText={error}
        isFooter={true}
        confirmButtonText="Add"
        cancelButtonText="Cancel"
        open={openChildModal}
        onConfirm={onConfirm}
        isDeleteButton={false}
        onCancel={onCancel}
      />
      <ModalBox
        title="Enter new password"
        size="md"
        description={
          <FormPassword
            label="New Password"
            name="password"
            showPassword={showPassword}
            toggleShowPassword={() => setShowPassword(!showPassword)}
            placeholder=""
            autoFocus={true}
            inputOnChange={(e) => {
              setPupilToChangePwd({
                ...pupilToChangePwd,
                password: e.target.value,
              });
              if (e.target.value.length < 8) setIsPasswordError(true);
              else setIsPasswordError(false);
            }}
            value={pupilToChangePwd.password}
            formProps={{ error: isPasswordError }}
            styles={{
              padding: "0 0 20px",
              width: "100%",
            }}
            errorMessage={
              <>
                <Typography
                  as="span"
                  paragraph={false}
                  sx={{
                    display: "block",
                    margin: "0",
                    color:
                      pupilToChangePwd.password.length >= 8 ? "green" : "red",
                  }}
                >
                  Password must contain at least 8 characters.
                </Typography>
              </>
            }
          />
        }
        isFooter={true}
        confirmButtonText="Change"
        cancelButtonText="Cancel"
        open={openPwdModal}
        onConfirm={onConfirmChangePassword}
        isDeleteButton={false}
        onCancel={onCancelChangePassword}
      />
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={isChildResetPswSuccess ? "success" : "error"}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </Box>
  );
};
