import * as React from "react";
import {
  Box,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Button,
} from "@mui/material";
import { FormInput } from "../FormInput";
import { AvatraBox } from "../AvatarBox";

export const UserItem = ({
  user,
  isChild = false,
  showResetButton = false,
  onChange,
  resetPassword = () => {},
  onSubmit,
}) => {
  return (
    <ListItem
      sx={{
        padding: "10px",
      }}
    >
      <ListItemAvatar>
        <AvatraBox user={user} />
      </ListItemAvatar>
      <ListItemText sx={{ width: "calc(100% - 112px)" }}>
        <form onSubmit={onSubmit}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <FormInput
              label="First Name"
              name="firstName"
              type="text"
              placeholder=""
              inputOnChange={onChange}
              value={user.firstName}
              styles={{
                margin: { xs: "0 0 10px", md: "0 10px 0 0" },
                width: {
                  xs: "100%",
                  md: isChild ? "calc(50% - 100px)" : "calc(33% - 25px)",
                },
              }}
              isRequired={true}
            />
            <FormInput
              label="Last Name"
              name="lastName"
              type="text"
              placeholder=""
              inputOnChange={onChange}
              value={user.lastName}
              styles={{
                margin: { xs: "0 0 10px", md: "0 10px 0 0" },
                width: {
                  xs: "100%",
                  md: isChild ? "calc(50% - 100px)" : "calc(33% - 25px)",
                },
              }}
              isRequired={true}
            />
            {!isChild && (
              <FormInput
                label="Email"
                name="email"
                type="text"
                placeholder=""
                value={user.email}
                styles={{
                  margin: { xs: "0 0 10px", md: "0 10px 0 0" },
                  width: { xs: "100%", md: "calc(33% - 25px)" },
                }}
                readOnly={true}
                disabled={true}
              />
            )}
            {isChild && (
              <FormInput
                label="Username"
                name="Username"
                type="text"
                placeholder=""
                value={user.username ? user.username : "-"}
                styles={{
                  margin: { xs: "0 0 10px", md: "0 10px 0 0" },
                  width: { xs: "100%", md: "calc(33% - 25px)" },
                }}
                readOnly={true}
                disabled={true}
              />
            )}
            {showResetButton && (
              <Button
                aria-label="reset password"
                color="primary"
                variant="outlined"
                sx={{
                  width: { xs: "100%", md: "225px" },
                  margin: { xs: "0 0 10px", md: "0 10px 0 0" },
                  fontSize: "13px",
                }}
                onClick={resetPassword}
              >
                Reset Password
              </Button>
            )}
            {/* <Button
              aria-label="update"
              color="primary"
              variant="contained"
              sx={{
                width: { xs: "100%", md: "75px" },
                fontSize: "13px",
              }}
              type="submit"
            >
              Update
            </Button> */}
          </Box>
        </form>
      </ListItemText>
    </ListItem>
  );
};
