import React from "react";
import {
  Card,
  Box,
  CardContent,
  CardMedia,
  Typography,
  CardActionArea,
  Link,
} from "@mui/material";
import { useStore } from "../../Store";
import { appUrl } from "../../config";

export const AppCard = ({ app = {}, viewType }) => {
  const defaultCoverPath = require("../../images/image.png");
  const user = useStore((state) => state.user);

  const colors = [
    {
      id: 1,
      name: "color-1",
    },
    {
      id: 2,
      name: "color-2",
    },
    {
      id: 3,
      name: "color-3",
    },
    {
      id: 4,
      name: "color-4",
    },
    {
      id: 5,
      name: "color-5",
    },
    {
      id: 6,
      name: "color-6",
    },
    {
      id: 7,
      name: "color-7",
    },
    {
      id: 8,
      name: "color-8",
    },
    {
      id: 9,
      name: "color-9",
    },
    {
      id: 10,
      name: "color-10",
    },
  ];

  return (
    <Card
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        // margin: "0 20px 20px 0",
        boxShadow: "none",
        borderRadius: "10px",
        ...(viewType === "grid"
          ? {
              flexDirection: "column",
              width: 200,
            }
          : {}),
        ":hover": { background: "rgba(0, 176, 255, 0.1)" },
      }}
      className={app.status === "Expired" || app.status === "Paused"
        ? ""
        : `${colors[Math.floor(Math.random() * 9) + 1].name}`}
    >
      <CardActionArea
        disabled={app.status === "Expired" || app.status === "Paused"}
        height="100%"
      >
        <Link
          href={`${appUrl}/?appid=${app.id}&userid=${user.id}`}
          rel="noreferrer"
          sx={{
            textDecoration: "none",
            color: "rgba(0, 0, 0, 0.87)",
          }}
        >
          <Box>
            {viewType === "grid" && (
              <Box sx={{ position: "relative" }}>
                {(app.status === "Expired" ||
                  app.status === "Paused") && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      zIndex: 2,
                      background: "rgba(0, 0, 0, 0.4)",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Typography
                      gutterBottom
                      variant="h5"
                      sx={{
                        fontWeight: "400",
                        fontSize: "17px",
                        textAlign: "center",
                        color: "#fff",
                      }}
                      component="div"
                    >
                      {app.status === "Expired"
                        ? "Expired"
                        : "Paused (Payment failed)"}
                    </Typography>
                  </Box>
                )}
                <CardMedia
                  component="img"
                  image={app.coverPath ? app.coverPath : defaultCoverPath}
                  alt="cover image"
                />
              </Box>
            )}
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                sx={{
                  fontWeight: "400",
                  fontSize: "17px",
                  ...(viewType === "grid"
                    ? { marginBottom: "0.35em" }
                    : { marginBottom: "0" }),
                  textAlign: "center",
                }}
                component="div"
              >
                {app.name}
              </Typography>
            </CardContent>
          </Box>
        </Link>
      </CardActionArea>
    </Card>
  );
};
