import React, { useState } from "react";
import { Button, Card, Stack } from "@mui/material";
import { Auth } from "aws-amplify";
import { useNavigate, NavLink } from "react-router-dom";
import { ForgotPassword } from "../../components/ForgotPassword";
import { ChangePassword } from "../../components/ChangePassword";

export const ForgotPasswordPage = ({ loadingCallback }) => {
  const [username, setUsername] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [showCountryCode, setShowCountryCode] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordAgain, setNewPasswordAgain] = useState("");
  const [isCodeError, setIsCodeError] = useState(false);
  const [isPasswordError, setIsPasswordError] = useState(false);
  const [isRepeatPasswordError, setIsRepeatPasswordError] = useState(false);
  const [isForgotPasswordScreen, setIsForgotPasswordScreen] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [changePasswordErrorMessage, setChangePasswordErrorMessage] =
    useState("");
  const navigate = useNavigate();
  const onUsernameChange = (e) => {
    setUsername(e.target.value);
    if (isNaN(e.target.value) || e.target.value === "") {
      setShowCountryCode(false);
    } else {
      setShowCountryCode(true);
    }
    if (e.target.value === "") {
      setIsError(true);
      setErrorMessage("Enter a valid input");
    } else {
      setIsError(false);
      setErrorMessage("");
    }
  };
  const onPasswordChange = (e) => {
    if (e.target.name === "new_password") {
      setNewPassword(e.target.value);
      if (e.target.value.length < 8) {
        setIsPasswordError(true);
      } else {
        setIsPasswordError(false);
      }
      if(newPasswordAgain !== "" && e.target.value.trim() === newPasswordAgain) {
        setIsRepeatPasswordError(false);
      }
    }
    if (e.target.name === "new_password_again") {
      setNewPasswordAgain(e.target.value);
      if (e.target.value.length < 8 || newPassword.trim() !== e.target.value) {
        setIsRepeatPasswordError(true);
      } else {
        setIsRepeatPasswordError(false);
      }
    }
  };
  const onResetPasswordSubmit = async (e) => {
    e.preventDefault();
    if (newPassword < 8) {
      setIsPasswordError(true);
      return true;
    }
    if (newPasswordAgain < 8 || newPassword !== newPasswordAgain) {
      setIsRepeatPasswordError(true);
      return true;
    }
    try {
      loadingCallback(true, "changing your password...");
      const userName = isNaN(username) ? username : `${countryCode}${username}`;
      await Auth.forgotPasswordSubmit(userName, code, newPassword);
      loadingCallback(false, "");
      navigate("/login");
    } catch (err) {
      loadingCallback(false, "");
      setIsCodeError(true);
      setChangePasswordErrorMessage(err.message);
    }
  };
  const onForgotPasswordSubmit = async (e) => {
    if (e) e.preventDefault();

    if (username === "") {
      setIsError(true);
      setErrorMessage("Enter a valid input");
      return true;
    }

    try {
      const userName = isNaN(username) ? username : `${countryCode}${username}`;
      loadingCallback(true, "sending the code...");
      await Auth.forgotPassword(userName);
      loadingCallback(false, "");
      setIsForgotPasswordScreen(false);
    } catch (err) {
      loadingCallback(false, "");
    }
  };
  return (
    <Card
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: 500,
        width: "100%",
        margin: "0 auto",
        padding: "30px",
      }}
      margin={{ sm: "40px auto 0", md: "60px auto 0" }}
    >
      <Stack
        sx={{
          width: "100%",
        }}
      >
        {isForgotPasswordScreen ? (
          <ForgotPassword
            username={username}
            errorMessage={errorMessage}
            showCountryCode={showCountryCode}
            isError={isError}
            onUsernameChange={onUsernameChange}
            onForgotPasswordSubmit={onForgotPasswordSubmit}
            codeChange={(country_code) => setCountryCode(country_code)}
          />
        ) : (
          <ChangePassword
            code={code}
            newPassword={newPassword}
            newPasswordAgain={newPasswordAgain}
            changePasswordErrorMessage={changePasswordErrorMessage}
            isCodeError={isCodeError}
            isPasswordError={isPasswordError}
            isRepeatPasswordError={isRepeatPasswordError}
            onPasswordChange={onPasswordChange}
            onCodeChange={(e) => setCode(e.target.value)}
            onResetPasswordSubmit={onResetPasswordSubmit}
            showPassword={showPassword}
            toggleShowPassword={() => setShowPassword(!showPassword)}
          />
        )}
        {isCodeError && (
          <Button
            variant="contained"
            onClick={() => {
              setIsCodeError(false);
              onForgotPasswordSubmit();
            }}
            sx={{
              marging: "0 0 20px",
            }}
          >
            Resend code again
          </Button>
        )}
        <Button variant="text">
          <NavLink to={`/login`} style={{ textDecoration: "none" }}>
            Back to login
          </NavLink>
        </Button>
      </Stack>
    </Card>
  );
};
