import React from "react";
import {
  Box,
  Card,
  CardActionArea,
  Button,
  CardContent,
  ListItemAvatar,
  Typography,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { AvatraBox } from "../AvatarBox";

export const ChildCard = ({ user = {}, onclick, color }) => {
  return (
    <Card
      sx={{
        borderRadius: "8px",
        padding: "0",
        boxShadow: "none",
      }}
    >
      <Box
        className={color.name}
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          height: "100px",
          margin: "0 0 20px",
        }}
      >
        <ListItemAvatar
          sx={{
            position: "absolute",
            bottom: "-20px",
          }}
        >
          <AvatraBox user={user} />
        </ListItemAvatar>
      </Box>
      <CardContent
        sx={{
          textAlign: "center",
        }}
      >
        <Typography variant="h5" sx={{ fontSize: "1.2rem" }}>
          {user.firstName} {user.lastName}
        </Typography>
      </CardContent>
      <CardActionArea
        sx={{
          padding: "10px 0 10px 10px",
          ":hover": {
            color: "transparent",
          },
          ":focus": {
            color: "transparent",
          },
        }}
      >
        <Button size="small" onClick={onclick} endIcon={<ArrowForwardIcon />}>
          View products and reports
        </Button>
      </CardActionArea>
    </Card>
  );
};
