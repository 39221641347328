import React from "react";
import { TextField } from "@mui/material";

export const FormInput = ({
  inputId,
  label,
  type,
  placeholder,
  value,
  inputOnChange,
  name,
  readOnly = false,
  errorMessage = "",
  isRequired = false,
  isError,
  styles,
  disabled,
  autoFocus
}) => {
  return (
    <>
      <TextField
        error={isError}
        id={inputId}
        name={name}
        label={label}
        value={typeof value !== "undefined" ? value : ""}
        placeholder={placeholder}
        onChange={inputOnChange}
        helperText={errorMessage}
        type={type}
        readOnly={readOnly}
        required={isRequired}
        disabled={disabled}
        sx={styles}
        autoFocus={autoFocus}
        fullWidth
      />
    </>
  );
};
