import React, { useState } from "react";
import { Button, Card, Stack, Typography } from "@mui/material";
import { FormPassword } from "../FormPassword";

export const NewPassword = ({
  newPassword,
  newPasswordAgain,
  isPasswordError,
  isRepeatPasswordError,
  onPasswordChange,
  onNewPasswordSubmit,
  showPassword,
  toggleShowPassword,
}) => {
  const [userNewPassword, setUserNewPassword] = useState(newPassword);
  const [userRepeatPassword, setUserRepeatPassword] =
    useState(newPasswordAgain);
  return (
    <Card
      sx={{
        maxWidth: 500,
        width: "100%",
        margin: "0 auto",
        padding: "30px",
      }}
    >
      <Typography
        variant="h1"
        sx={{
          lineHeight: "1.1",
        }}
        fontSize="2rem"
        fontWeight="bold"
        margin="0 0 20px"
      >
        Enter new password
      </Typography>
      <form onSubmit={onNewPasswordSubmit}>
        <FormPassword
          label="New Password"
          name="new_password"
          showPassword={showPassword}
          toggleShowPassword={toggleShowPassword}
          placeholder=""
          autoFocus={true}
          inputOnChange={(e) => {
            onPasswordChange(e);
            setUserNewPassword(e.target.value);
          }}
          value={userNewPassword}
          formProps={{ error: isPasswordError }}
          styles={{
            display: "block",
            padding: "0 0 20px",
          }}
          errorMessage={
            <>
              <Typography
                color={newPassword.length >= 8 ? "green.500" : "red.500"}
              >
                Password must contain at least 8 characters.
              </Typography>
            </>
          }
        />
        <FormPassword
          label="Enter New Password Again"
          name="new_password_again"
          showPassword={showPassword}
          toggleShowPassword={toggleShowPassword}
          placeholder=""
          autoFocus={false}
          inputOnChange={(e) => {
            onPasswordChange(e);
            setUserRepeatPassword(e.target.value);
          }}
          value={userRepeatPassword}
          formProps={{ error: isRepeatPasswordError }}
          styles={{
            display: "block",
            padding: "0 0 20px",
          }}
          errorMessage={"Your password should match with the above one."}
        />
        <Stack spacing={6}>
          <Button
            type="submit"
            variant="contained"
            sx={{ fontSize: "1rem", fontWeight: "bold" }}
          >
            Create Password
          </Button>
        </Stack>
      </form>
    </Card>
  );
};
