import React from "react";
export const MenuIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="16"
      viewBox="0 0 24 16"
    >
      <path
        fill="#223F99"
        fillRule="nonzero"
        d="M1.25.417h17.5a1 1 0 0 1 1 1v.166a1 1 0 0 1-1 1H1.25a1 1 0 0 1-1-1v-.166a1 1 0 0 1 1-1zm0 6.5h17.5a1 1 0 0 1 1 1v.166a1 1 0 0 1-1 1H1.25a1 1 0 0 1-1-1v-.166a1 1 0 0 1 1-1zm0 6.5h2.333a1 1 0 0 1 1 1v.166a1 1 0 0 1-1 1H1.25a1 1 0 0 1-1-1v-.166a1 1 0 0 1 1-1zm6.5 0h11a1 1 0 0 1 1 1v.166a1 1 0 0 1-1 1h-11a1 1 0 0 1-1-1v-.166a1 1 0 0 1 1-1z"
      />
    </svg>
  );
};
