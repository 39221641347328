import React, {useState, useEffect} from 'react'
import { Typography } from "@mui/material";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { NewPassword } from '../../components/NewPassword'
import { PageLayout } from '../../components/PageLayout';

export const VerificationPage = ({ loadingCallback }) => {

  const [user, setUser] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [countryCode] = useState("+91");
  const [firstName, setFirstName] = useState("");
  const [familyName, setFamilyName] = useState("");
  const [newPasswordAgain, setNewPasswordAgain] = useState("");
  const [isPasswordError, setIsPasswordError] = useState(false);
  const [isRepeatPasswordError, setIsRepeatPasswordError] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [isNewPasswordRequired, setIsNewPasswordRequired] = useState(false);
  const [signinErrorMessage, setSigninErrorMessage] = useState("");
  const [isSigninError, setIsSigninError] = useState(false);
  const navigate = useNavigate();


  const onPasswordChange = (e) => {
    if (e.target.name === "new_password") {
      setNewPassword(e.target.value);
      if (e.target.value.length < 8) {
        setIsPasswordError(true);
      } else {
        setIsPasswordError(false);
      }
    }
    if (e.target.name === "new_password_again") {
      setNewPasswordAgain(e.target.value);
      if (e.target.value.length < 8 || newPassword.trim() !== e.target.value) {
        setIsRepeatPasswordError(true);
      } else {
        setIsRepeatPasswordError(false);
      }
    }
  };

  const onNewPasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      loadingCallback(true, "Setting new password...");
      const response = await Auth.completeNewPassword(user, newPassword, {
        given_name: firstName,
        family_name: familyName,
      });
      loadingCallback(false, "");
      navigate("/");
    } catch (err) {
      loadingCallback(false, "");
      setIsSigninError(true);
      setSigninErrorMessage("Something went wrong. Please try again!");
    }
  };
  const signIn = async (username, password) => {
    if (username === "") {
      setIsSigninError(true);
      setSigninErrorMessage("Something went wrong. Please click the verify button again from the mail.");
      return true;
    }

    try {
      const userName = isNaN(username) ? username : `${countryCode}${username}`;
      loadingCallback(true, "signing in...");
      const user = await Auth.signIn(userName, password);
      loadingCallback(false, "");
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        const userAttributes = user?.challengeParam?.userAttributes;
        setFirstName(userAttributes.name);
        setFamilyName(userAttributes.family_name);
        setUser(user);
        setIsNewPasswordRequired(true);
        setIsSigninError(false);
      }
    } catch (err) {
      loadingCallback(false, "");
      setIsSigninError(true);
      setSigninErrorMessage("Something went wrong. Please click the verify button again from the mail.");
    }
  };

  useEffect(() => {
    if(window.location.pathname === "/verify") {
        const urlParams = new URLSearchParams(window.location.search);
        const username = urlParams.get("username");
        const password = urlParams.get("temp");
        localStorage.setItem("isNewChild", true)
        signIn(username, password)
      }
  }, []);

  return (
    <PageLayout isShow={false} isVerificationPage={true}>
      {
        isSigninError ? 
        <Typography
            variant="h5"
            color="primary"
            noWrap
            sx={{
                flexGrow: 1,
                margin: "0 0 30px",
                color: "red",
            }}
        >
            {signinErrorMessage}
        </Typography> : 
        <Typography
          variant="h5"
          color="primary"
          noWrap
          sx={{
            flexGrow: 1,
            fontWeight: "bold",
            margin: "0 0 30px",
            color: "green",
          }}
        >
          Please enter password to complete the verification.
        </Typography>
      }
      {
          isNewPasswordRequired ? 
          <NewPassword
              newPassword={newPassword}
              newPasswordAgain={newPasswordAgain}
              isPasswordError={isPasswordError}
              isRepeatPasswordError={isRepeatPasswordError}
              onPasswordChange={onPasswordChange}
              onNewPasswordSubmit={onNewPasswordSubmit}
              showPassword={showNewPassword}
              toggleShowPassword={() => setShowNewPassword(!showNewPassword)}
          /> : <></>
      }
    </PageLayout>
  )
}
