import React from "react";
import { Box, Typography, Card, CardContent, CardMedia } from "@mui/material";

const defaultCoverPath = require("../../images/image.png");

export const CheckoutFormWithoutChild = ({ subscription }) => {
  return (
    <>
      <Box>
        <Card
          sx={{
            display: "flex",
            justifyContent: "space-between",
            margin: "0 0 10px",
            boxShadow: "none",
            border: "1px solid rgba(0, 176, 255, 0.4)",
          }}
        >
          <CardMedia
            component="img"
            image={
              subscription.productCoverPath
                ? subscription.productCoverPath
                : defaultCoverPath
            }
            sx={{
              width: 200,
            }}
            alt="cover image"
          />
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              sx={{
                fontWeight: "400",
                fontSize: "17px",
                marginBottom: "0.35em",
              }}
              component="div"
            >
              {subscription.productName}
            </Typography>
          </CardContent>
        </Card>
        <Typography
          sx={{ fontSize: 18, fontWeight: 700 }}
          color="text.secondary"
          gutterBottom
        >
          {subscription.child}
        </Typography>
      </Box>
    </>
  );
};
