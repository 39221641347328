import React, { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: { xs: "20px 0 0", md: 3 } }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const BasicTabs = ({ tabItems }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="course tabs"
          textColor="secondary"
          indicatorColor="secondary"
        >
          {tabItems.map((tabItem, index) => (
            <Tab
              key={tabItem.id}
              sx={{ fontWeight: "600" }}
              label={tabItem.title}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>
      {tabItems.map((tabItem, index) => (
        <TabPanel key={tabItem.id} value={value} index={index}>
          {tabItem.content}
        </TabPanel>
      ))}
    </Box>
  );
};
