import React from "react";
import { Avatar } from "@mui/material";
import { stringAvatar } from "../../utils/functions";

export const AvatraBox = ({ user, width = 32, height = 32 }) => {
  return (
    <Avatar
      sx={{ width: width, height: height, fontWeight: "600" }}
      {...stringAvatar(`${user.firstName} ${user.lastName}`)}
    />
  );
};
