import React from "react";
import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  Typography,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { red } from "@mui/material/colors";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: "100%"
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    fontFamily: ["Open Sans", "sans-serif"],
    fontWeight: 300,
  },
  "& .MuiDialog-paper": {
    transition: "none",
    boxShadow: "none",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export const ModalBox = ({
  title,
  description,
  open,
  isFooter = false,
  cancelButtonText = "Cancel",
  confirmButtonText = "Create",
  onConfirm = () => {},
  onCancel = () => {},
  isDeleteButton = false,
  children,
  confirmButtonType = false,
  size = "sm",
  errorText = "",
}) => {
  const content = () => (
    <>
      <DialogContent dividers>
        {description ? description : children}
      </DialogContent>
      {isFooter && (
        <DialogActions
          sx={{
            ...(!!errorText
              ? { alignItems: "flex-start" }
              : { alignItems: "flex-end" }),
            ...(!!errorText ? { justifyContent: "space-between" } : {}),
          }}
        >
          {!!errorText && (
            <Typography
              sx={{ fontSize: 18, fontWeight: 500, color: red[600] }}
              color="text.secondary"
              gutterBottom
            >
              {errorText}
            </Typography>
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="secondary"
              color={isDeleteButton ? "primary" : "error"}
              sx={{
                margin: "0 10px 0 0",
                color: "#f44336",
                ":hover": {
                  background: "#ffe1de",
                },
              }}
              onClick={onCancel}
            >
              {cancelButtonText}
            </Button>
            {confirmButtonType ? (
              <Button
                variant="contained"
                type="submit"
                color={isDeleteButton ? "error" : "primary"}
              >
                {confirmButtonText}
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={onConfirm}
                color={isDeleteButton ? "error" : "primary"}
              >
                {confirmButtonText}
              </Button>
            )}
          </Box>
        </DialogActions>
      )}
    </>
  );
  return (
    <BootstrapDialog
      onClose={onCancel}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={size}
      BackdropProps={{ style: { backgroundColor: "rgba(66, 71, 112, 0.4)" } }}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onCancel}>
        {title}
      </BootstrapDialogTitle>
      {confirmButtonType ? (
        <form onSubmit={onConfirm}>{content()}</form>
      ) : (
        content()
      )}
    </BootstrapDialog>
  );
};
