import React, { useState, useEffect } from "react";
import { Box, Typography, Chip, Link, Button } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { Auth } from "aws-amplify";
import { CHILD_ENROLLED_COURSES } from "../../graphql/queries";
import { DataTable } from "../../components/DataTable";
import { ModalBox } from "../../components/ModalBox";
import { useStore } from "../../Store";
import { formatDate } from "../../utils/functions";
import { appUrl } from "../../config";

export const ChildPage = ({ loadingCallback }) => {
  const [courses, setCourses] = useState([]);
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const children = useStore((state) => state.children);
  const child = children.find((c) => c.id === id);

  const navigate = useNavigate();

  const [getChildCourses] = useLazyQuery(CHILD_ENROLLED_COURSES, {
    onCompleted: (data) => {
      const bunldes = data.indPurchasedBundles
        .filter((ipb) => !ipb.bundle.isBundleApp)
        .map((pBundle) => ({
          id: pBundle.bundle.id,
          name: pBundle.bundle.bundleName,
          status: pBundle.status,
          accessUntil: formatDate(pBundle.accessUntil),
          isApp: false,
        }));
      const courseList = data.indPurchasedProducts.map((pProduct) => ({
        id: pProduct.product.id,
        name: pProduct.product.productName,
        status: pProduct.status,
        accessUntil: formatDate(pProduct.accessUntil),
        isApp: false,
      }));
      const qapps = data.indPurchasedBundles
        .filter((ipb) => ipb.bundle.isBundleApp)
        .map((pBundle) => ({
          id: pBundle?.bundle?.QApp[0]?.id,
          name: pBundle.bundle.bundleName,
          status: pBundle.status,
          accessUntil: formatDate(pBundle.accessUntil),
          isApp: true,
        }));
      setCourses([...bunldes, ...courseList, ...qapps]);
      loadingCallback(false, "");
    },
    onError: () => {
      loadingCallback(false, "");
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (id) {
      loadingCallback(true, "fetching list of enrolled courses...");
      getChildCourses({
        variables: {
          bundle_where: {
            student: {
              id: { equals: id },
            },
          },
          qapp_where: {
            userId: {
              equals: id
            }
          },
          product_where: {
            student: {
              id: { equals: id },
            },
          }
        },
      });
    }
  }, [id, loadingCallback, getChildCourses]);
  const getStatusValue = (value) => {
    if (value === "Active") {
      return {
        label: value,
        style: {
          color: "rgb(34, 154, 22)",
          fontWeight: 700,
          background: "rgba(84, 214, 44, 0.16)",
          borderRadius: "6px",
          textTransform: "capitalize",
          fontSize: "0.75rem",
          border: "none",
          height: "22px",
          minWidth: "22px",
        },
      };
    }
    if (value === "Cancelled") {
      return {
        label: value,
        style: {
          color: "rgb(183, 33, 54)",
          fontWeight: 700,
          background: "rgba(255, 72, 66, 0.16)",
          borderRadius: "6px",
          textTransform: "capitalize",
          fontSize: "0.75rem",
          border: "none",
          height: "22px",
          minWidth: "22px",
        },
      };
    }
    return {
      label: value,
      style: {
        color: "rgb(183, 33, 54)",
        fontWeight: 700,
        background: "rgba(255, 72, 66, 0.16)",
        borderRadius: "6px",
        textTransform: "capitalize",
        fontSize: "0.75rem",
        border: "none",
        height: "22px",
        minWidth: "22px",
      },
    };
  };
  const logoutAndPrefillUsername = async () => {
    if (Auth) {
      loadingCallback(true, "signing out...");
      sessionStorage.clear();
      sessionStorage.setItem("username", child.username);
      await Auth.signOut();
      navigate("/");
      window.location.reload();
    }
  };
  return (
    <Box>
      <Typography
        gutterBottom
        variant="h1"
        sx={{
          fontSize: "1.5rem",
          color: "rgb(27, 110, 194)",
          fontWeight: 600,
          margin: "0 0 20px",
        }}
      >
        {child.firstName} {child.lastName}
      </Typography>
      <Typography
        gutterBottom
        variant="p"
        component="p"
        sx={{
          fontSize: "1rem",
          margin: "0 0 20px",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <Button
          onClick={() => setOpen(!open)}
          sx={{
            padding: 0,
            margin: 0,
            width: "auto",
            textTransform: "none",
            minWidth: "auto",
            fontSize: "1rem",
            lineHeight: "normal",
            ":hover": {
              background: "none",
            },
          }}
        >
          Login
        </Button>{" "}
        &nbsp;as a student to access the app.
      </Typography>
      <Typography
        gutterBottom
        variant="h2"
        sx={{ fontSize: "1rem", fontWeight: 600, margin: "0 0 20px" }}
      >
        List of courses enrolled in:
      </Typography>
      <DataTable
        visibleFields={["name", "status", "accessUntil", "report"]}
        searchColumns={[
          { id: "11", value: "name", name: "Name" },
          { id: "12", value: "accessUntil", name: "Access Until" },
        ]}
        columns={[
          { field: "name", headerName: "Name", flex: 0.5 },
          {
            field: "accessUntil",
            headerName: "Access Until",
            type: "date",
            flex: 0.5,
          },
          {
            field: "status",
            headerName: "Status",
            renderCell: (row) => {
              return (
                <Chip variant="outlined" {...getStatusValue(row.status)} />
              );
            },
            flex: 0.5,
          },
          {
            field: "report",
            headerName: "Report",
            renderCell: (row) => {
              return row.isApp ? (
                <Link
                  href={`${appUrl}/?appid=${row.id}&userid=${id}`}
                  target="_self"
                  rel="noreferrer"
                  sx={{
                    textDecoration: "none",
                    color: "#fff",
                    padding: "5px 10px",
                    borderRadius: "6px",
                    background: "rgb(27, 110, 194)",
                  }}
                >
                  View Report
                </Link>
              ) : (
                <></>
              );
            },
            flex: 0.5,
          },
        ]}
        originalRows={courses}
        noDataMsg="It seems that you haven't purchased any products."
      />
      <ModalBox
        title="You are about to Log out"
        description={`Use your child's username and password to login again to access the app.`}
        isFooter={true}
        confirmButtonText="Yes"
        cancelButtonText="No"
        open={open}
        onConfirm={logoutAndPrefillUsername}
        isDeleteButton={true}
        onCancel={() => {
          setOpen(false);
        }}
      />
    </Box>
  );
};
