import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "rgb(27, 110, 194)",
    },
    secondary: {
      main: "#00b0ff",
    },
    tertiary: {
      main: "#424770",
    },
    error: {
      main: "#f44336",
    },
  },
  typography: {
    fontFamily: ['"Open Sans"', "sans-serif", "Arial"].join(","),
    p: {
      fontFamily: ['"Open Sans"', "sans-serif", "Arial"].join(","),
    },
  },
});

export default theme;
